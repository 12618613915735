import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat as StreamChatComponent, Thread, Channel, ChannelHeader, MessageList, MessageInput, Window, ChannelList } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import ClientNavbar from '../../../layouts/ClientNavbar';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { useParams, Link } from 'react-router-dom';
const Chat = () => {

    const [client, setClient] = useState(null);
    const [channel, setChannel] = useState(null);
    const [user, setUser] = useState(null);

    // your Stream app information
    const apiKey = 'yyctydyjthy8';
    const { username } = useParams(); // Ensure this name matches the route parameter

    const getInitials = (fullName) => {
        const nameArray = fullName.trim().split(' ');
        if (nameArray.length === 1) {
            return nameArray[0].charAt(0).toUpperCase();
        }
        return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
    };

    const getAvatarUrl = (fullName) => {
        const initials = getInitials(fullName);
        // UI Avatars URL: generates a circle avatar with initials, customize the size and background color
        return `https://ui-avatars.com/api/?name=${initials}&background=random&rounded=true`;
    };

    const fetchUserToken = async (userId) => {
        const response = await fetch(`${window.location.origin}/api/generate-chat-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
        });

        const data = await response.json();
        return data.token;
    };

    const fetchUserData = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const fitnessGroupId = userData.fitness_group || '';
                const userImage = userData.image || null;
                

                // Only proceed if there is at least one fitness group

                // Note: a bunch of code that will need ot be swapped when you change from an array to a field
                if (fitnessGroupId) {
                    //rest of code
                    const firstGroupRef = doc(db, "fitness_group", fitnessGroupId);
                    const firstGroupSnap = await getDoc(firstGroupRef);
                    let firstGroupName = '';
                    if (firstGroupSnap.exists()) {
                        firstGroupName = firstGroupSnap.data().name;
                    }

                    

                    // Set user with the first fitness group name
                    setUser({
                        id: user.uid,
                        name: `${userData.firstName} ${userData.lastName}`,
                        image: userImage || getAvatarUrl(`${userData.firstName} ${userData.lastName}`),
                        coachId: userData.coachUid,
                        coachName: username,
                        fitnessGroupName: firstGroupName,  // Set the first fitness group name
                        fitnessGroupId: fitnessGroupId,
                    });
                } else {
                    // If no fitness groups, just set user without the group name
                    setUser({
                        id: user.uid,
                        name: `${userData.firstName} ${userData.lastName}`,
                        image: userImage || getAvatarUrl(`${userData.firstName} ${userData.lastName}`),
                        coachId: userData.coachUid,
                        coachName: username,
                        fitnessGroupId: null,
                    });
                }
            }
        }
    };

    useEffect(() => {
        const initChat = async () => {
            await fetchUserData();
        };

        initChat();
    }, []);


    useEffect(() => {
        const initializeChatClient = async () => {
            if (user) {
                // Initialize the Stream Chat client
                const chatClient = StreamChat.getInstance(apiKey);
                const token = await fetchUserToken(user.id);

                await chatClient.connectUser(
                    {
                        id: user.id,
                        name: user.name,
                        image: user.image
                    },
                    token
                );

                const tertiaryChannel = chatClient.channel('messaging', `${user.id}-1on1`, {
                    members: [user.coachId, user.id],
                    name: `Private chat with your coach`,
                });

                await tertiaryChannel.create();

                if (user.fitnessGroupId) {
                    console.log("user is part of a fitness group...generating channels")
                    
                    const defaultChannelId = `${user.fitnessGroupId}-general`;

                    const defaultChannel = chatClient.channel('messaging', defaultChannelId, {
                        name: `${user.fitnessGroupName} general channel`,
                    });

                    const secondaryChannel = chatClient.channel('messaging', `${user.fitnessGroupId}-QA`, {
                        name: `${user.fitnessGroupName} Q&A channel`,
                    });

                    await defaultChannel.watch();
                    await secondaryChannel.create();

                    setChannel(defaultChannel);
                } else {
                    console.log("user is not part of a fitness group...generating channels")
                    setChannel(tertiaryChannel);
                }
                

                setClient(chatClient);
                
            }
        };

        initializeChatClient();
    }, [user]); // Run this effect when the user state changes

    useEffect(() => {
        // Cleanup function to disconnect user on component unmount
        return () => {
            if (client) {
                client.disconnect();
            }
        };
    }, [client]);


    if (!client || !channel) {
        return <div>Loading...</div>;
    }

    const options = {
        limit: 10,
      };

    const filters = {
        type: 'messaging',
        id: { $in: [`${user.fitnessGroupId}-general`,  `${user.fitnessGroupId}-QA`, `${user.id}-1on1`] },
    };

    console.log(filters);

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <ClientNavbar />
            <div className="h-screen flex-grow-1 overflow-y-lg-auto">
                <StreamChatComponent client={client} theme="messaging light">
                    <ChannelList
                        filters={filters}
                        sort={{ last_message_at: -1 }}
                        options={{options}}
                    />
                    <Channel>
                        <Window>
                            <ChannelHeader />
                            <MessageList />
                            <MessageInput />
                        </Window>
                        <Thread />
                    </Channel>
                </StreamChatComponent>
            </div>
        </div>
    );
};

export default Chat;