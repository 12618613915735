import { setDoc, doc, getDocs, collection, query, where, Timestamp } from 'firebase/firestore';
import {auth, db } from '../firebase/firebase';


export const createUserDocument = async (user, role, coachUid, firstName, lastName, businessName, businessType, username) => {
    
  const userRef = doc(db, 'users', user.uid);

  const userData = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: user.email || '',
    phoneNumber: '',
    role: role,
    appointments: [],
    created_at: Timestamp.now(),
    deleted_at: null,
    archived_at: null
  };

  if (role === 'coach') {
    userData.clients = [];
    userData.username = username || '';
    userData.businessName = businessName || '';
    userData.businessType = businessType || '';

    //fields to store credit package details
    userData.pack1Quantity = 10;
    userData.pack1Discount = 0.1;
    userData.pack2Quantity = 20;
    userData.pack2Discount = 0.2;
    userData.creditCost = 100;

    userData.publicProfile = false;

    // Initialize an empty 'exercises' subcollection with a placeholder document
    const placeholderExerciseRef = doc(collection(userRef, 'exercises'));
    await setDoc(placeholderExerciseRef, { placeholder: true }); // Creates the subcollection with a dummy document

  }

  if (role === 'client') {
    userData.coachUid = coachUid;
    userData.credits = 0;
    userData.clientStatus = 'new';
    userData.workouts = [];
  }

  return setDoc(userRef, userData);
};

export const updateUserWithStripeId = async (uid, stripeAccountId) => {
  try {
    await setDoc(doc(db, 'users', uid), { stripeAccountId }, { merge: true });
    console.log('Stripe ID updated successfully');
  } catch (error) {
    console.error('Error updating user with Stripe ID:', error);
  }
};

export const checkUsernameAvailability = async (username) => {


  if (!username) {
      return 'Username is required.';
  }

  // Reference to users collection
  const usersCollectionRef = collection(db, 'users');

  // Query for the username in the users collection
  const usernameQuery = query(usersCollectionRef, where('username', '==', username));

  try {
      const querySnapshot = await getDocs(usernameQuery);
      if (!querySnapshot.empty) {
          // If the query snapshot is not empty, the username is already taken
          const currentUser = auth.currentUser;
          let userDoc = querySnapshot.docs[0];
          if (userDoc.id === currentUser.uid) {
              return 'This is your current username.';
          } else {
              return 'Username already taken, please choose another one.';
          }
      } else {
          // If the query snapshot is empty, the username is available
          return 'This username is available!';
      }
  } catch (error) {
      console.error('Error checking username: ', error);
      return 'Error occurred while checking username availability.';
  }
};

export const createStripeAccount = async (userData) => {
  try {
    console.log('Pre create');
    console.log(userData);
    const response = await fetch('http://localhost:3001/create-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Stripe account creation response:', data);

    if (data.id) {
      console.log('Stripe account created successfully:', data.id);
      return data.id; // Return the Stripe account ID
    } else {
      console.error('Stripe account creation failed');
      return null; // Return null or an appropriate error indication
    }
  } catch (error) {
    console.error('Error in createStripeAccount:', error);
    return null; // Return null or an appropriate error indication
  }
};

export const createStripeAccountLink = async (accountId) => {
  try {
    const linkResponse = await fetch('http://localhost:3001/create-account-link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accountId })
    });

    if (!linkResponse.ok) {
      throw new Error('Failed to create account link');
    }

    const linkData = await linkResponse.json();
    console.log(linkData);

    if (linkData.url) {
      console.log('Stripe account-link created successfully:', linkData);
      return linkData.url; // Return the URL for Stripe onboarding
    } else {
      console.error('Failed to retrieve Stripe onboarding URL');
      return null; // Return null or an appropriate error indication
    }
  } catch (error) {
    console.error('Error in createStripeAccountLink:', error);
    return null; // Return null or an appropriate error indication
  }
};

        // // Request to create an account link
        // const linkResponse = await fetch('http://localhost:3001/create-account-link', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ accountId: data.id })
        // });

        // console.log(linkResponse);

        // if (!linkResponse.ok) {
        //   throw new Error('Failed to create account link');
        // }
        
        // const linkData = await linkResponse.json();
        // console.log(linkData);

        // if (linkData.url) {
        //   console.log('Stripe account-link created successfully:', linkData);
        //   window.location.href = linkData.url; // Redirect to Stripe onboarding
        // } else {
        //   console.error('Failed to retrieve Stripe onboarding URL');
        //   // Handle error scenario

        // // Save Stripe account ID to your database

        // //create an account link

        // // Continue with onboarding or other actions