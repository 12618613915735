import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../../layouts/Coachportal_sidebar';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import AddClients from './tabs/AddClients';  // Import the AddClients component
import GroupPrograms from './tabs/Programs';

const GroupDetails = () => {
  const { groupId } = useParams();  // Get the group ID from the URL
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('clients');
  const [groupDetails, setGroupDetails] = useState({});  // For storing group details
  const [metrics, setMetrics] = useState('');  // For future metrics

  // Handle tab change and deeplinking
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`#${tab}`);
  };

  useEffect(() => {
    // Set the tab based on URL hash (for deeplinking)
    const hash = location.hash.replace('#', '');
    if (hash) {
      setActiveTab(hash);
    }

    // Fetch group details based on groupId
    fetchGroupDetails();
  }, [location, groupId]);

  const fetchGroupDetails = async () => {
    // Fetch the group details from Firestore based on groupId
    const groupDocRef = doc(db, 'fitness_group', groupId);
    const groupDoc = await getDoc(groupDocRef);

    if (groupDoc.exists()) {
      const groupData = groupDoc.data();
      setGroupDetails({
        name: groupData.name || 'Untitled Group',
        startDate: groupData.startDate || 'No Start Date',
        endDate: groupData.endDate || 'No End Date',
      });
    } else {
      console.error('Group not found');
    }
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />

      <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-lg-auto">
        <div className="container-fluid">
          <a href="/dashboard/groups" className="pt-5 mb-5 btn btn-light btn-sm">&larr; Back</a>

          {/* Session Details Box */}
          <div className="card pt-3 m-4 rounded-0">
            <div className="card-body py-5">
              <div className="row align-items-center">
                <div className="col">
                  <h5 className="card-title">{groupDetails.name}</h5>
                  <p className="text-muted">
                    Runs from {new Date(groupDetails.startDate).toLocaleDateString('en-US')} - {new Date(groupDetails.endDate).toLocaleDateString('en-US')}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tab Navigation */}
          <ul className="nav nav-pills ms-4 pt-5">
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'clients' ? 'active' : ''}`} onClick={() => handleTabChange('clients')}>
                Clients
              </button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'workouts' ? 'active' : ''}`} onClick={() => handleTabChange('workouts')}>
                Workouts
              </button>
            </li>
            {/* Add other tabs as needed */}
          </ul>

          {/* Tab Content */}
          <div className="tab-content">
            {activeTab === 'clients' && (
              <div className="tab-pane fade show active">
                {/* Render the AddClients component here */}
                <AddClients groupId={groupId} />
              </div>
            )}
            {activeTab === 'workouts' && (
              <div className="card pt-3 m-4 rounded-0">
                {/* Workout tab content */}
                <GroupPrograms groupId={groupId} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
