import React, { useState, useEffect } from 'react';
import { auth, db} from '../../firebase/firebase';
import { updateProfile } from "firebase/auth";
import { doc, updateDoc, query as queryFirebase, where, getDocs, onSnapshot, collection} from 'firebase/firestore';
import { checkUsernameAvailability } from '../../utils/user';


import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';


function Profile() {

    const fields = ['username', 'firstName', 'lastName', 'businessName', 'businessType', 'otherBusinessType'];
    const [usernameAvailability, setUsernameAvailability] = useState('');

    const [formFields, setFormFields] = useState({
        username: '',
        firstName: '',
        lastName: '',
        businessName: '',
        businessType: '',
        otherBusinessType: ''
    });

    const checkAvailability = async (username) => {
        const availability = await checkUsernameAvailability(username);
        setUsernameAvailability(availability);
      };

    const user = auth.currentUser;

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const userDocRef = doc(db, 'users', user.uid);
    
        // Reference to users collection
        const usersCollectionRef = collection(db, 'users');
    
        // Query for the username in the users collection
        const usernameQuery = queryFirebase(usersCollectionRef, where('username', '==', formFields.username));
    
        getDocs(usernameQuery)
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    // If the query snapshot is not empty, the username is already taken
                    let userDoc = querySnapshot.docs[0];
                    if (userDoc.id !== user.uid) {
                        // If the user ID does not match the current user, it means the username is taken by another user
                        alert('Username already taken, please choose another one.');
                    } else {
                        // If the user ID matches the current user, proceed with updating profile
                        updateUserProfile(userDocRef);
                    }
                } else {
                    // If the query snapshot is empty, the username is available. Proceed with updating profile
                    updateUserProfile(userDocRef);
                }
            })
            .catch((error) => {
                console.error('Error checking username: ', error);
            });
    };

    const updateUserProfile = (userDocRef) => {
        // The username is available, update the user info in Firestore
        updateDoc(userDocRef, {
            username: formFields.username,
            firstName: formFields.firstName,
            lastName: formFields.lastName,
            businessName: formFields.businessName,
            businessType: formFields.businessType === 'Other' ? formFields.otherBusinessType : formFields.businessType,
        })
        .then(() => {
            // Update the username in authentication
            return updateProfile(user, { displayName: formFields.username });
        })
        .then(() => {
            // After Firestore and authentication updates are both successful, display a success message
            alert('Profile information and username successfully updated!');
        })
        .catch((error) => {
            console.log('Error updating user:', error);
        });
    };

    useEffect(() => {
        const userDocRef = doc(db, 'users', user.uid);
    
        const unsubscribeUser = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                let data = doc.data();
    
                // Set the formFields using callback function
                setFormFields(prevState => {
                    // For each field, if it exists in Firestore data, use it
                    fields.forEach(field => {
                        if (data[field]) {
                            prevState[field] = data[field];
                        }
                    });
    
                    return {...prevState};
                });
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });
    
        return () => {
            unsubscribeUser();
        }; // Cleanup subscriptions on unmount
    }, [user.uid]);

    return (
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />

        {/* Main content */}
        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
            <Header title="Account" />

            

            {/* Main */}
            <main className="py-6 bg-surface-secondary">
                <div className="container py-10">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="card shadow border-0 mb-10">
                                <div className="card-body">
                                    <div className="mb-7">
                                        <h4 className="font-semibold mb-1">Profile</h4>
                                        <p className="text-sm text-muted"> Update your Profile information. </p>
                                    </div>
                                    {/* Start form */}
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-5">
                                            {/* Username */}
                                            <div className="col-12">
                                                <div className="">
                                                    <label className="form-label" htmlFor="username"> Username </label>
                                                    <div className="input-group position-relative">
                                                        <span className="input-group-text" id="">coachily.com/website/</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="username"
                                                            value={formFields.username}
                                                            placeholder="username"
                                                            aria-label="username"
                                                            aria-describedby=""
                                                            onChange={(e) => {
                                                                const newUsername = e.target.value;
                                                                console.log("Checking availability for username:", newUsername); // Log for debugging
                                                                setFormFields({ ...formFields, username: newUsername });
                                                                checkAvailability(newUsername);
                                                            }}
                                                        />
                                                    </div>
                                                    {usernameAvailability === 'Username already taken, please choose another one.' ? (
                                                        <div className="mt-2 invalid-feedback d-block">{usernameAvailability}</div>
                                                    ) : (
                                                        <div className="mt-2 valid-feedback d-block">{usernameAvailability}</div>
                                                    )}

                                                </div>
                                            </div>

                                            {/* First Name/Last Name */}
                                            <div className="col-md-6">
                                                <div className="">
                                                    <label className="form-label" htmlFor="first_name">
                                                        First name
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="first_name" 
                                                        value={formFields.firstName}
                                                        placeholder="First Name"
                                                        aria-label="First Name"
                                                        onChange={(e) => setFormFields({ ...formFields, firstName: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="">
                                                    <label className="form-label" htmlFor="last_name">
                                                        Last name
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="last_name" 
                                                        placeholder="Last Name"
                                                        aria-label="Last Name"
                                                        value={formFields.lastName}
                                                        onChange={(e) => setFormFields({ ...formFields, lastName: e.target.value })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Business Name */}
                                            <div className="col-md-6">
                                                <div className="">
                                                    <label className="form-label" htmlFor="formInputExample">
                                                    Business Name
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="formInputExample"
                                                        placeholder="Business Name"
                                                        aria-label="Business Name"
                                                        value={formFields.businessName}
                                                        onChange={(e) => setFormFields({ ...formFields, businessName: e.target.value })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Business Type */}
                                            <div className="col-md-6">
                                                <div className="">
                                                    <label className="form-label" htmlFor="businessType">
                                                    Business Type
                                                    </label>
                                                    <select 
                                                        value={formFields.businessType} 
                                                        className="form-select" 
                                                        id="businessType"
                                                        onChange={e => setFormFields({...formFields, businessType: e.target.value})}
                                                    >
                                                        <option value="" disabled>Select your option</option>
                                                        <option value="Chess Coach">Chess Coach</option>
                                                        <option value="Fitness Coach">Fitness Coach</option>
                                                        <option value="Other">Other</option>
                                                    </select>

                                                    {formFields.businessType === 'Other' && 
                                                        <input 
                                                            type="text" 
                                                            value={formFields.otherBusinessType} 
                                                            className="form-control mt-3" 
                                                            id="otherBusinessType"
                                                            placeholder="Please specify"
                                                            onChange={(e) => setFormFields({ ...formFields, otherBusinessType: e.target.value })}
                                                        />
                                                    }
                                                </div>
                                            </div>



                                        </div>
                                        
                                        {/* Form Submission */}
                                        <div className="text-end py-6">
                                            <button type="button" className="btn btn-sm btn-neutral me-2">
                                            Cancel
                                            </button>
                                            <button type="submit" className="btn btn-sm btn-primary">
                                            Save
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        </div>
    );
};

export default Profile;
