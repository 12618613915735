


export const formatPhoneNumber = (value) => {
    if (!value) return value;
  
    // Only allow numbers
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    // Break the string into parts for formatting
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };