import React, { useState } from 'react';
import { createAppointment } from '../../../utils/appointments';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AddAppointmentModal = ({ clientUserId, coachId, onClose, show, onAppointmentAdded }) => {
  const [appointmentData, setAppointmentData] = useState({
    date: '',
    time: '',
    notes: '',
    location_type: 'in person',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      await createAppointment(clientUserId, coachId, "private", appointmentData);
      if (typeof onAppointmentAdded === "function") {
        onAppointmentAdded();
      }
      onClose();
    } catch (error) {
      console.error(error);
      // Optionally, show an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create new Private Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={appointmentData.date}
              onChange={(e) => setAppointmentData({ ...appointmentData, date: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Time</Form.Label>
            <Form.Control
              type="time"
              value={appointmentData.time}
              onChange={(e) => setAppointmentData({ ...appointmentData, time: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Location Type</Form.Label>
            <Form.Select
              value={appointmentData.location_type}
              onChange={(e) => setAppointmentData({ ...appointmentData, location_type: e.target.value })}
            >
              <option value="in person">In Person</option>
              <option value="virtual">Virtual</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Session Name</Form.Label> 
            <Form.Control
              as="textarea"
              value={appointmentData.notes}
              onChange={(e) => setAppointmentData({ ...appointmentData, notes: e.target.value })}
            />
          </Form.Group>
          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>Close</Button>
        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAppointmentModal;
