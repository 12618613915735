// CreateWorkout.js
import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { db } from '../../../../firebase/firebase';
import { collection, addDoc, getDocs, doc, updateDoc, arrayUnion, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../../layouts/Coachportal_sidebar';
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import Header from '../../../../layouts/Header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const CreateWorkout = () => {
    const [workoutName, setWorkoutName] = useState('');
    const [duration, setDuration] = useState('');
    const [difficulty, setDifficulty] = useState('easy');
    const [dateAssigned, setDateAssigned] = useState('');
    const [exercises, setExercises] = useState(Array(5).fill().map(() => ({ name: '', sets: '', reps: '', weight: '', unitType: 'reps' })));
    const [exerciseOptions, setExerciseOptions] = useState([]);
    const [defaultExercises, setDefaultExercises] = useState([]); // Array for all exercise names
    const [isGenerating, setIsGenerating] = useState(false); // State for loading button

    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [customPrompt, setCustomPrompt] = useState(''); // State for custom prompt
    const [modalPrompt, setModalPrompt] = useState(''); // State for custom prompt

    const [videoLink, setVideoLink] = useState(''); // Video link state
    const [videoModal, setVideoModal] = useState(false); // Video link state
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(null);


    const navigate = useNavigate();
    const auth = getAuth();
    const coachId = auth.currentUser.uid;

    const { groupId} = useParams();

    // Fetch exercises from Firestore
    useEffect(() => {
        const fetchExercises = async () => {
        // Fetch coach-specific exercises
            const coachExercisesSnapshot = await getDocs(collection(db, "users", coachId, "exercises"));
            const coachExercises = coachExercisesSnapshot.docs.map(doc => ({
                label: doc.data().name,
                value: doc.id,
                defaultSets: doc.data().defaultSets || '',
                defaultUnits: doc.data().defaultUnits || '',
                unitType: doc.data().unitType || 'reps',
                defaultWeight: doc.data().defaultWeight || ''
            }));

            // Fetch general exercises
            const generalExercisesSnapshot = await getDocs(collection(db, "exercises"));
            const generalExercises = generalExercisesSnapshot.docs.map(doc => ({
                label: doc.data().name,
                value: doc.id,
                defaultSets: doc.data().defaultSets || '',
                defaultUnits: doc.data().defaultUnits || '',
                unitType: doc.data().unitType || 'reps',
                defaultWeight: doc.data().defaultWeight || ''
            }));

            const combinedExercises = [...coachExercises, ...generalExercises];
            const uniqueExercises = Array.from(new Map(combinedExercises.map(ex => [ex.label, ex])).values());

            setExerciseOptions(uniqueExercises);


            // Store exercise names in default_exercises
            const exerciseNames = uniqueExercises.map(ex => ex.label);
            setDefaultExercises(exerciseNames);
        };

        fetchExercises();
    }, [coachId]);

    // Video modal
    const handleVideoIconClick = (index) => {
        setCurrentExerciseIndex(index);
        setVideoLink(exercises[index].videoLink || ''); // Set the current video link
        setVideoModal(true); // Open the modal
    };

    const handleSaveVideoLink = () => {
        const newExercises = exercises.map((exercise, i) => {
            if (i === currentExerciseIndex) {
              return { ...exercise, videoLink };
            }
            return exercise;
          });
        setExercises(newExercises);
        setVideoModal(false);
    };

    // Fetch other workouts under the same group
    const fetchOtherWorkouts = async () => {
        const q = query(collection(db, 'assigned_workouts'), where('group', '==', groupId));
        const otherWorkoutsSnapshot = await getDocs(q);
        return otherWorkoutsSnapshot.docs.map(doc => doc.data());
    };

    const handleGenerateWorkout = async () => {
        setIsGenerating(true);

        try {

            // Fetch other workouts in the same group
            const otherWorkouts = await fetchOtherWorkouts();

            // Create a prompt based on the workout and other workouts in the group
            const prompt = customPrompt || `
                Generate a workout plan based on the following details:
                Workout Name: ${workoutName || 'Unnamed'}
                Duration: ${duration} minutes
                Difficulty: ${difficulty}
                Date Assigned: ${dateAssigned}
                Other Workouts in the Program: ${JSON.stringify(otherWorkouts)}
            `;

            console.log(prompt);


            const response = await fetch('/api/generate-workouts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    workoutDetails: {
                        type: 'Full Body',
                        difficulty,
                        duration,
                    },
                    default_exercises: defaultExercises,
                    prompt: prompt
                }),
            });

            const data = await response.json();

            console.log('Generated workout:', data);

            let rawResponse = data;

            // Clean the response: Remove markdown-like syntax like backticks and "```json"
            rawResponse = rawResponse.replace(/```json|```/g, '');

            console.log(rawResponse);

            let workoutPlan;
            try {
                workoutPlan = JSON.parse(rawResponse);  // Parse the workout string as JSON
            } catch (error) {
                console.error('Error parsing workout plan JSON:', error);
                alert('Failed to parse the workout plan');
                return;
            }

            if (workoutPlan) {

                console.log('workoutPlan:', workoutPlan);

                // Assuming the response returns exercises in a structured way
                if (Array.isArray(workoutPlan)) {
                    const generatedExercises = workoutPlan.map(ex => ({
                        name: ex.name,
                        sets: ex.sets,
                        reps: ex.unit,
                        weight: ex.weight,
                        unitType: ex.unitType,
                    }));
                    console.log(generatedExercises);
                    setExercises(generatedExercises);
                } else {
                    console.error('Expected an array but received:', workoutPlan);
                    alert('Failed to generate workout. No exercises found.');
                }

            } else {
                alert('Failed to generate workout.');
            }
        } catch (error) {
            console.error('Error generating workout:', error);
            alert('An error occurred while generating the workout.');
        }

        setIsGenerating(false);
    };

    const handleOpenModal = async () => {
        // Set the default prompt if customPrompt is empty
        if (!customPrompt) {
            const otherWorkouts = await fetchOtherWorkouts(); // Fetch other workouts
            const defaultPrompt = `
                Generate a workout plan based on the following details, with the most important being duration:
                Workout Name: ${workoutName || 'Unnamed'}
                Duration (length of workout): Approximately ${duration} minutes, including rest time, reps, and sets. Assume a 90 second rest between sets. Less exercises are okay for shorter sets, and more exercises are okay for longer sets. 
                Difficulty: ${difficulty}
                Date Assigned: ${dateAssigned}
                Other Workouts in the Program: ${JSON.stringify(otherWorkouts)}
            `;
            setModalPrompt(defaultPrompt);
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSavePrompt = () => {
        handleCloseModal();
    };


    const handleCreateExercise = async (inputValue, index) => {
    try {
        const newExerciseRef = await addDoc(collection(db, "users", coachId, "exercises"), { name: inputValue });
        const newOption = { label: inputValue, value: newExerciseRef.id };
        setExerciseOptions(prevOptions => [...prevOptions, newOption]);
        handleExerciseChange(index, 'name', newOption.label);
    } catch (error) {
        console.error("Error creating new exercise: ", error);
    }
    };

    // Custom Dropdown Indicator component that returns null effectively removing the arrow
    const DropdownIndicator = props => {
        return null;
    };

    const handleExerciseChange = (index, field, value, selectedOption) => {
        const newExercises = exercises.map((exercise, i) => {
          if (i === index) {
            if (field === 'name' && selectedOption) { // Only update other fields if the name field is being changed to a selected option
              return {
                ...exercise,
                name: value,
                sets: selectedOption.defaultSets,
                reps: selectedOption.defaultUnits,
                unitType: selectedOption.unitType,
                weight: selectedOption.defaultWeight,
              };
            }
            return { ...exercise, [field]: value };
          }
          return exercise;
        });
        setExercises(newExercises);
      };

    const handleAddExercise = () => {
        setExercises([...exercises, { name: '', sets: '', reps: '', weight: '', unitType: 'reps' }]);
    };

    const handleDeleteExercise = index => {
        // Remove the exercise at the given index
        setExercises(currentExercises => currentExercises.filter((_, i) => i !== index));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const filledExercises = exercises.filter(ex => ex.name || ex.sets || ex.reps);
        for (let exercise of filledExercises) {
            if (!exercise.name || !exercise.sets || !exercise.reps) {
            alert('Please complete all fields for each exercise that has any information entered.');
            return;
            }
        }

        const workoutData = {
        name: workoutName,
        duration,
        difficulty,
        dateAssigned: dateAssigned,
        exercises: filledExercises,
        created_at: new Date().getTime(),
        group: groupId
        };

        try {
            const docRef = await addDoc(collection(db, 'assigned_workouts'), workoutData);
            console.log("Workout created with ID: ", docRef.id);

            // Update the cohort's document
            const cohortRef = doc(db, 'fitness_group', groupId);
            await updateDoc(cohortRef, {
                workouts: arrayUnion(docRef.id)  // Update the cohort to reference the new workout
            });    

            navigate(-1); // navigate back to the previous page
        } catch (error) {
        console.error('Error creating workout: ', error);
        }
    };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />
        <div className="h-screen bg-surface-primary flex-grow-1 d-flex flex-column overflow-y-lg-auto">

            <Header title="Create Custom Workout" />
            <div className="container-fluid">
                <form onSubmit={handleSubmit}>

                    <div className="card m-4 rounded-0">
                        <div className="card-body">
                            <div className="mb-4">
                                <input
                                    type="text"
                                    className="form-control-lg" 
                                    style={{ fontSize: "1.5rem", height: "auto", padding: "0px 10px" }}  // Custom style for even larger appearance
                                    placeholder="Workout Name"
                                    value={workoutName}
                                    onChange={(e) => setWorkoutName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-floating">

                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Estimated Length (minutes)"
                                            value={duration}
                                            onChange={(e) => setDuration(e.target.value)}
                                        />
                                        <label htmlFor="floatingInput">
                                            <i className="bi bi-clock-history me-2">  Minutes</i>
                                        </label>
                                    </div>
                                </div>
                            

                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <select
                                            className="form-select"
                                            value={difficulty}
                                            onChange={(e) => setDifficulty(e.target.value)}
                                        >
                                            <option value="">Select difficulty</option>
                                            <option value="easy">Easy</option>
                                            <option value="medium">Medium</option>
                                            <option value="hard">Hard</option>
                                            <option value="insanity">Insanity</option>
                                        </select>
                                        <label htmlFor="floatingSelect">
                                            <i className="bi bi-bar-chart-steps me-2"></i>Difficulty
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-floating">
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={dateAssigned}
                                            onChange={(e) => setDateAssigned(e.target.value)}
                                            required
                                        />
                                        <label htmlFor="floatingInputDate">
                                            <i className="bi bi-calendar3 me-2"></i>Date Assigned
                                        </label>
                                    </div>
                                </div>

                                <div className="mt-6 d-flex justify-content-center align-items-center">
                                    <button type="button" className="btn btn-primary me-2" onClick={handleGenerateWorkout} disabled={isGenerating}>
                                        {isGenerating ? 'Generating...' : 'Generate Workout'}
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleOpenModal}>
                                        Edit Prompt
                                    </button>
                                </div>
                                
                            </div>
                        </div>

                    </div>

                    <div className="card rounded-0 m-4">

                        <div className="card-header border-bottom d-flex align-items-center">
                            <h5 className="me-auto h3">Exercises</h5>
                            <button type="button" onClick={handleAddExercise}>+ Add Row</button>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-hover table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" className="d-none d-md-table-cell"></th>
                                        <th scope="col">Exercise Name</th>
                                        <th scope="col" style={{ minWidth: "105px" }}>Sets</th>
                                        <th scope="col" style={{ minWidth: "105px" }}>Units </th>
                                        <th scope="col">Unit Type</th>
                                        <th scope="col">Weight (lbs)</th>
                                        <th scope="col"><i className="fa-sharp fa-solid fa-video"></i></th>
                                        <th scope="col" className="d-none d-md-table-cell">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exercises.map((exercise, index) => (
                                        <tr key={index}>
                                            <td className="d-none d-md-table-cell">{index + 1}</td>
                                            <td>
                                            <CreatableSelect
                                                isClearable
                                                isSearchable
                                                value={exercise.name ? { label: exercise.name, value: exercise.name } : null}
                                                onChange={(selectedOption) => handleExerciseChange(index, 'name', selectedOption ? selectedOption.label : '', selectedOption)}
                                                options={exerciseOptions}
                                                onCreateOption={(inputValue) => handleCreateExercise(inputValue, index)}
                                                placeholder="Add an exercise"
                                                components={{ DropdownIndicator }}
                                                menuPortalTarget={document.body}  // Attach the dropdown to the body of the page
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} //custom style to make the dropdown appear outside of card
                                            />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={exercise.sets !== null ? exercise.sets : ''}  // Fix for null values
                                                    onChange={(e) => handleExerciseChange(index, 'sets', e.target.value)}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={exercise.reps !== null ? exercise.reps : ''}  // Fix for null values
                                                    onChange={(e) => handleExerciseChange(index, 'reps', e.target.value)}
                                                    placeholder={exercise.unitType === 'time' ? 'Minutes' : exercise.unitType === 'distance' ? 'Meters' : 'Reps'}
                                                />
                                            </td>

                                            <td>
                                                <select
                                                    value={exercise.unitType}
                                                    onChange={(e) => handleExerciseChange(index, 'unitType', e.target.value)}
                                                >
                                                    <option value="reps">Reps</option>
                                                    <option value="time">Time</option>
                                                    <option value="distance">Distance</option>
                                                </select>
                                            </td>

                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={exercise.weight !== null ? exercise.weight : ''}  // Fix for null values
                                                    onChange={(e) => handleExerciseChange(index, 'weight', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <i
                                                    className="fa-regular fa-clapperboard-play"
                                                    style={{ color: exercise.videoLink ? 'blue' : 'gray', cursor: 'pointer' }}
                                                    onClick={() => handleVideoIconClick(index)}
                                                />
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                <button onClick={() => handleDeleteExercise(index)} className="btn-sm">
                                                    X
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <button type="submit" className="mt-3 btn btn-success w-100">Create Workout</button>

                </form>
            </div>

            {/* Modal for Editing the Prompt */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        className="form-control"
                        rows="8"
                        value={modalPrompt}
                        onChange={(e) => setModalPrompt(e.target.value)}
                        placeholder="Edit the prompt for AI generation..."
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setCustomPrompt(modalPrompt);
                        handleSavePrompt();
                    }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Editing Video link */}
            <Modal show={videoModal} onHide={() => setVideoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Video Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Enter video URL"
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setVideoModal(false)}>
                    Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSaveVideoLink}>
                    Save
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    </div>
  );
};

export default CreateWorkout;
