import { db } from '../firebase/firebase';
import { doc, collection, addDoc, updateDoc, getDoc, getDocs } from 'firebase/firestore';
import stripe from 'stripe';


export const addTransaction = async (userId, transactionData) => {

    const userRef = doc(db, 'users', userId);
    const transactionsCollection = collection(userRef, 'transactions');

    await addDoc(transactionsCollection, transactionData);
    
    
    // Fetch the current user's data
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists) {
        throw new Error('User does not exist!');
    }
    
    // Update the credits
    await updateDoc(userRef, {
        credits: (Number(userSnapshot.data().credits) + Number(transactionData.creditsPurchased))
    });

};

export const fetchTransactions = async (userId) => {
    console.log(userId);
    const userRef = doc(db, 'users', userId);
    const transactionsCollection = collection(userRef, 'transactions');

    const transactionsSnapshot = await getDocs(transactionsCollection);

    console.log("2");

    const transactions = [];
    transactionsSnapshot.forEach((doc) => {
        transactions.push({
            id: doc.id,
            ...doc.data()
        });
    });

    console.log(transactions);
    return transactions;
};

export const createStripeCheckoutSession = async (accountId, unit_quantity, unit_amount, application_fee, product_name, cancel_url, clientId, coachId) => {

    // console.log('Coach ID (transactions):', coachId);

    const metadata = {
        clientId: clientId, // Assuming clientId is the ID of the client purchasing credits
        coachId: coachId,
        creditsPurchased: unit_quantity,
        product_name: product_name
        // Assuming coachId is the ID of the coach associated with this purchase
    };

    // await new Promise(resolve => setTimeout(resolve, 5000));
    
    const response = await fetch('http://localhost:3001/create-checkout-session', {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            accountId: accountId,
            unit_quantity,
            unit_amount,
            application_fee,
            product_name,
            cancel_url, 
            metadata 
        }),
    });
    const session = await response.json();
    console.log(session);
    return session.id;
};
