import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, query, where, doc, updateDoc} from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import Sidebar from '../../../layouts/Coachportal_sidebar';
import Header from '../../../layouts/Header';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const ProgramGroupsList = () => {
  const [groups, setGroups] = useState([]);
  const [clients, setClients] = useState([]);
  const [newGroup, setNewGroup] = useState({ name: '', startDate: '', endDate: '', selectedClients: [] });

  useEffect(() => {
    fetchGroups();
    fetchClients();
  }, []);

  const fetchGroups = async () => {
    const auth = getAuth();
    const user = auth.currentUser; // Fetches the currently logged-in coach
    
    // Fetch only groups created by the logged-in coach and where deleted_at is null
    const q = query(
      collection(db, 'fitness_group'),
      where('coachId', '==', user.uid),
      where('deleted_at', '==', null)
    );
    const querySnapshot = await getDocs(q);

    let groupsArray = [];
    querySnapshot.forEach((doc) => {
      groupsArray.push({ id: doc.id, ...doc.data() });
    });

    setGroups(groupsArray);
  };

  const fetchClients = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    const q = query(
      collection(db, "users"),
      where("role", "==", "client"),
      where("coachUid", "==", user.uid)
    );

    const querySnapshot = await getDocs(q);

    let clientsArray = [];
    querySnapshot.forEach((doc) => {
      const clientData = doc.data();
      if (clientData.deleted_at === null || clientData.deleted_at === undefined) {
        clientsArray.push({ id: doc.id, ...clientData });
      }
    });

    setClients(clientsArray);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroup({ ...newGroup, [name]: value });
  };

  const handleClientSelect = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setNewGroup({ ...newGroup, selectedClients: selectedOptions });
  };

  const handleCreateGroup = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      const groupDocRef = await addDoc(collection(db, 'fitness_group'), {
        name: newGroup.name,
        startDate: newGroup.startDate,
        endDate: newGroup.endDate,
        members: newGroup.selectedClients,
        coachId: user.uid, // Add coachId to the group
        created_at: new Date().getTime(),
        workouts: [],
        deleted_at: null, // Initially set deleted_at to null
        archived_at: null
      });

      const groupId = groupDocRef.id;

      for (const clientId of newGroup.selectedClients) {
        const clientRef = doc(db, 'users', clientId);
        await updateDoc(clientRef, {
          fitness_group: groupId
        });
      }

      fetchGroups();
      setNewGroup({ name: '', startDate: '', endDate: '', selectedClients: [] });
    } catch (error) {
      console.error('Error creating new group:', error);
    }
  };

  // const handleDeleteGroup = async (groupId) => {
  //   try {
  //     const groupRef = doc(db, 'fitness_group', groupId);
  //     await updateDoc(groupRef, {
  //       deleted_at: Timestamp.now() // Update deleted_at with the current timestamp
  //     });
  //     fetchGroups(); // Refresh the list after deletion
  //   } catch (error) {
  //     console.error('Error deleting group:', error);
  //   }
  // };


  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />

      <div className="h-screen flex-grow-1 d-flex flex-column overflow-y-lg-auto">
        <Header title="Fitness Groups" />
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">
            <div className="vstack gap-4">
              <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                <div className="d-flex gap-3">
                  <div className="input-group input-group-sm input-group-inline">
                    <span className="input-group-text pe-2">
                      <i className="bi bi-search" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </div>
                <div className="btn-group">
                  <button className="btn btn-sm btn-neutral text-primary" data-bs-toggle="modal" data-bs-target="#createGroupModal">
                    Create New Group
                  </button>
                </div>
              </div>

              <div className="card">
                <div className="card-header border-bottom d-flex align-items-center">
                  <h5 className="me-auto">All Group Programming</h5>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-nowrap">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Group Name</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groups.map((group, index) => (
                        <tr key={index}>
                          <td>
                            <Link className="text-heading font-semibold" to={`/dashboard/groups/${group.id}`}>
                              {group.name}
                            </Link>
                          </td>
                          <td>{group.startDate}</td>
                          <td>{group.endDate}</td>
                          <td>
                            <Link to={`/dashboard/groups/${group.id}#clients`} className="btn btn-sm btn-neutral me-2">Add Clients</Link>
                            <Link to={`/dashboard/groups/${group.id}#workouts`} className="btn btn-sm btn-neutral me-2">Adjust Workouts</Link>
                            {/* <button onClick={() => handleDeleteGroup(group.id)} className="btn btn-sm btn-danger">
                              Delete
                            </button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Create Group Modal */}
      <div className="modal fade" id="createGroupModal" tabIndex="-1" aria-labelledby="createGroupModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createGroupModalLabel">Create New Fitness Group</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="groupName" className="form-label">Group Name</label>
                <input type="text" className="form-control" id="groupName" name="name" value={newGroup.name} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="startDate" className="form-label">Start Date</label>
                <input type="date" className="form-control" id="startDate" name="startDate" value={newGroup.startDate} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="endDate" className="form-label">End Date</label>
                <input type="date" className="form-control" id="endDate" name="endDate" value={newGroup.endDate} onChange={handleInputChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="clients" className="form-label">Select Clients</label>
                <small className="text-muted d-block mb-2">You can always add more clients later</small>
                <select multiple className="form-control" id="clients" onChange={handleClientSelect}>
                  {clients.map(client => (
                    <option key={client.id} value={client.id}>{client.firstName} {client.lastName}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleCreateGroup} data-bs-dismiss="modal">Create Group</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramGroupsList;
