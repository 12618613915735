import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { addTransaction } from '../../../utils/transactions'; // Replace path accordingly
import { getAuth } from 'firebase/auth';
import { useParams } from 'react-router-dom';

const AddCreditsModal = ({ onClose, onTransactionAdded }) => {
    const { id } = useParams();
    const clientId = id;

    const [formData, setFormData] = useState({
        creditsPurchased: 0,
        packageCost: 0,
        notes: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async () => {

        const auth = getAuth();
        const coachId = auth.currentUser.uid;

        const transactionData = {
            ...formData,
            transactionType: 'purchase',
            purchaseType: 'credit purchase',
            transactionDate: new Date().toISOString(),
            coachId
        };

        try {
            await addTransaction(clientId, transactionData);
            onClose();
            alert("Transaction added successfully!");
        } catch (error) {
            alert("Error adding transaction: " + error.message);
        }
    };
    

    const computePricePerCredit = () => {
        const costPerCredit = formData.packageCost / formData.creditsPurchased;
        return isFinite(costPerCredit) ? costPerCredit.toFixed(2) : 0;
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Credits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Credits Purchased</Form.Label>
                        <Form.Control 
                            type="number" 
                            name="creditsPurchased" 
                            value={formData.creditsPurchased} 
                            onChange={handleChange} 
                            placeholder="Enter number of credits"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Package Cost ($)</Form.Label>
                        <Form.Control 
                            type="number" 
                            name="packageCost" 
                            value={formData.packageCost} 
                            onChange={handleChange} 
                            placeholder="Enter cost of the package (optional)"
                        />
                        <Form.Text className="text-muted">
                            Your price per credit is ${computePricePerCredit()}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            name="notes" 
                            value={formData.notes} 
                            onChange={handleChange} 
                            placeholder="Enter any notes here (e.g. payment method, client notes, etc.). These will be visible to both you and the client."
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Add Credits
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCreditsModal;
