// Example for the WebsiteBuilder.js file
import React from 'react';
import Sidebar from '../../layouts/Coachportal_sidebar';

const WebsiteBuilder = () => {
  return (
    <div>
      <Sidebar />
      {/* Your page content */}
    </div>
  );
};

export default WebsiteBuilder;




// import React from 'react';
// import { getAuth } from "firebase/auth";
// import { getFirestore, doc, setDoc } from "firebase/firestore";

// const db = getFirestore();

// class WebsiteBuilder extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { websiteTitle: '' };

//     this.handleWebsiteTitleChange = this.handleWebsiteTitleChange.bind(this);
//     this.updateWebsiteTitle = this.updateWebsiteTitle.bind(this);
//   }

//   handleWebsiteTitleChange(event) {
//     this.setState({ websiteTitle: event.target.value });
//   }

//   async updateWebsiteTitle() {
//     const auth = getAuth();
//     const user = auth.currentUser;

//     const documentId = user.uid;  // Document ID is User ID

//     try {
//         await setDoc(doc(db, "users", documentId), {
//           websiteTitle: this.state.websiteTitle
//         });
//     } catch (error) {
//         console.error("Error updating title: ", error);
//     }
//   }

//   render() {
//     return (
//       <div>
//         <div>
//           <label>
//             Website title:
//             <input type="text" value={this.state.websiteTitle} onChange={this.handleWebsiteTitleChange} />
//           </label>
//         </div>
//         <button type="button" onClick={this.updateWebsiteTitle}>
//           Update Website Title
//         </button>
//       </div>
//     );
//   }
// }

// export default WebsiteBuilder;
