// Home.js
import React from 'react';
import { useParams } from 'react-router-dom';
import ClientNavbar from '../../layouts/ClientNavbar';

const Home = () => {
const { username } = useParams()

  return (
    <div>
      <ClientNavbar username={username} />
      <h1>Welcome to the Client Home Page</h1>
    </div>
  );
}

export default Home;
