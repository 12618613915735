import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';

const Overview = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState({
    email: '',
    phone_number: '',
    firstName: '',
    lastName: '',
    clientSince: '',
    created_at: null,
  });
  const [originalData, setOriginalData] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    const fetchClientDetails = async () => {
      const clientRef = doc(db, 'users', id);
      const clientSnapshot = await getDoc(clientRef);
      if (clientSnapshot.exists()) {
        const data = clientSnapshot.data();
        const createdAt = data.created_at?.toDate();
        let formattedDate = 'No sign-up date listed';
        if (createdAt) {
          formattedDate = createdAt.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
        }

        const newClientData = {
          email: data.email || '',
          phone_number: data.phone_number || '',
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          clientSince: formattedDate,
          created_at: data.created_at,
        };

        setClientData(newClientData);
        setOriginalData(newClientData);
        setIsFormChanged(false);
      }
    };

    fetchClientDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData(prev => ({ ...prev, [name]: value }));
    setIsFormChanged(true);
  };

  // Handle saving changes
  const handleSave = async () => {
    const clientRef = doc(db, 'users', id);
    try {
      await updateDoc(clientRef, {
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        email: clientData.email,
        phone_number: clientData.phone_number,
      });
      setOriginalData(clientData);
      setIsFormChanged(false);
      alert('Client information updated successfully!');
    } catch (error) {
      console.error('Error updating client information: ', error);
    }
  };

  const handleCancel = () => {
    setClientData(originalData);
    setIsFormChanged(false);
  };

  return (
    <div>
      <div className="card-body py-5 row">
        {['firstName', 'lastName'].map(field => (
          <div className="col" key={field}>
            <label htmlFor={field} className="form-label text-muted small">
              {field === 'firstName' ? 'First Name' : 'Last Name'}
            </label>
            <input
              type="text"
              className="form-control"
              name={field}
              value={clientData[field] || ''}
              onChange={handleInputChange}
            />
          </div>
        ))}
      </div>

      <div className="card-body row">
        {['email', 'phone_number'].map(field => (
          <div className="col" key={field}>
            <label htmlFor={field} className="form-label text-muted small">
              {field === 'email' ? 'Email' : 'Phone number'}
            </label>
            <input
              type="text"
              className="form-control"
              name={field}
              value={clientData[field] || ''}
              onChange={handleInputChange}
              disabled={field === 'email'}
              readOnly={field === 'email'}
            />
          </div>
        ))}
      </div>

      <div className="card-body row">
        <div className="col">
          <label htmlFor="clientSince" className="form-label text-muted small">Client since</label>
          <input
            type="text"
            className="form-control"
            id="clientSince"
            value={clientData.clientSince || ''}
            disabled
            readOnly
          />
        </div>
      </div>

      {/* Cancel and Save buttons */}
      <div className="d-flex justify-content-end mb-5 me-6">
        <button 
          onClick={handleCancel} 
          className="btn btn-secondary me-2" 
          disabled={!isFormChanged}
        >
          Cancel Changes
        </button>
        <button onClick={handleSave} className="btn btn-primary" disabled={!isFormChanged}>
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default Overview;
