import React, { useState} from 'react';
import { useParams } from 'react-router-dom';
import ClientAppointmentsTable from '../../../shared/appointments/ClientAppointmentsTable';
import { auth } from '../../../../firebase/firebase';

const Schedule = ({ refreshKey, setRefreshKey }) => {
    const { id } = useParams();
    const coachId = auth.currentUser.uid;
    const [filter, setFilter] = useState('All active');


    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter);
        // The filtering logic can be applied here or in the ClientAppointmentsTable component/data source.
    };

    return (
    <div className="">
        {/* Table Header */}
        <div className="card-header border-bottom d-flex align-items-center">
            <h5 className="me-auto h3">Sessions</h5>
            <div className="dropdown">
                <button className="btn btn-sm btn-neutral dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    {filter}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Upcoming'); }}>Upcoming</a></li>
                    <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Completed'); }}>Completed</a></li>
                    <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('All active'); }}>All active</a></li>
                    <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Cancelled'); }}>Cancelled</a></li>
                </ul>
            </div>
        </div>
        {/* Table Itself */}
        <ClientAppointmentsTable key={refreshKey} userId={coachId} clientId={id} type="client" filter={filter} />
    </div>
    );
};

export default Schedule;
