import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase'; 
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import logo from '../../assets/logo.png';
import { createUserDocument, checkUsernameAvailability, createStripeAccount, updateUserWithStripeId, createStripeAccountLink } from '../../utils/user'; // import createUserDocument
import { formatPhoneNumber } from '../../utils/formValidation'

function SignUpPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(''); // New state for first name
  const [lastName, setLastName] = useState(''); // New state for last name
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('Fitness Coach');
  const [username, setUsername] = useState('');
  const [usernameAvailability, setUsernameAvailability] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const checkAvailability = async (username) => {
    const availability = await checkUsernameAvailability(username);
    setUsernameAvailability(availability);
  };
  


  const register = async (e) => {
    e.preventDefault();
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await createUserDocument(userCredential.user, 'coach', '', firstName, lastName, businessName, businessType, username);

      // Create Stripe account and save the returned ID in a constant
      // const stripeAccountId = await createStripeAccount({
      //   email,
      //   firstName,
      //   lastName,
      //   phoneNumber,
      //   websiteUrl,
      //   // other relevant data
      // });
  
      // if (!stripeAccountId) {
      //   throw new Error('Failed to create Stripe account');
      // }
  
      // Save Stripe account ID to user's document and create the account link in parallel
      // const user = auth.currentUser;
      // const [_, stripeAccountLinkUrl] = await Promise.all([
      //   updateUserWithStripeId(user.uid, stripeAccountId),
      //   createStripeAccountLink(stripeAccountId)
      // ]);
  
      // if (stripeAccountLinkUrl) {
      //   // Redirect to Stripe onboarding
      //   window.location.href = stripeAccountLinkUrl;
      // } else {
      //   navigate('/dashboard');
      //   throw new Error('Failed to create Stripe account link');
      // }

      // If you want to navigate to the dashboard after the Stripe onboarding, you can do it here
      navigate('/dashboard');
  
    } catch (error) {
      alert(error.message);
      // Handle any errors that occurred in the process
    }
  };

  
//   const register = (e) => {
//     e.preventDefault();
  
//     createUserWithEmailAndPassword(auth, email, password)
//     .then((userCredential) => { 
//       // as this page is for coach registration, role is 'coach'
//       return createUserDocument(userCredential.user, 'coach', '', firstName, lastName, businessName, businessType, username);
//     })
//     .then(() => {
//       return createStripeAccount({
//         // Include any necessary data
//         email,
//         firstName,
//         lastName,
//         // other relevant data
//       });
//     })
//     // .then((stripeResponse) => {
//     //   console.log('Stripe account created:', stripeResponse);
//     //   // Optionally save Stripe account ID to your database

//     //   // Check if stripeResponse has a URL for redirection
//     //   if (stripeResponse) {
//     //     // Redirect to Stripe onboarding
//     //     window.location.href = stripeResponse.url;
//     //   } else {
//     //     // Navigate to the dashboard or next step if no Stripe URL
//     //     navigate('/dashboard');
//     //   }
//     // })
//     // // .then(() => {
//     // //   navigate('/dashboard');
//     // // })
//     .catch((error) => alert(error.message));
// };

const googleSignIn = () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      // as this page is for coach registration, role is 'coach'
      const names = result.user.displayName ? result.user.displayName.split(' ') : ['', ''];
      return createUserDocument(result.user, 'coach', '', names[0], names[1]);
    })
    .then(() => {
      navigate('/dashboard');
    })
    .catch((error) => {
        alert(error.message);
    });
};



  return (
    <div className="px-5 py-5 p-lg-0 bg-surface-secondary">
      <div className="d-flex justify-content-center">
        <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
          {/* Logo */}
          <a className="d-block" href="#">
            <img
                src={logo}
                className="h-10"
                alt="..."
            />
          </a>
          {/* Title */}
          <div className="mt-32 mb-20">
            <h1 className="ls-tight font-bolder display-6 text-white mb-5">
              Hello :)
            </h1>
            <p className="text-white text-opacity-75">
              Get ready to launch your very own customized group fitness app
            </p>
          </div>
          {/* Circle */}
          <div className="w-56 h-56 bg-orange-500 rounded-circle position-absolute bottom-0 end-20 transform translate-y-1/3" />
        </div>
        <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
          <div className="row">
            <div className="col-lg-10 col-md-9 col-xl-9 mx-auto ms-xl-0">
              <div className="mt-10 mt-lg-5 mb-6 d-flex align-items-center d-lg-block">
                <h1 className="ls-tight font-bolder h2">Let's get started...</h1>
              </div>

              <form className="mb-6" onSubmit={register}>

                {/* Email & password */}
                <div className="row g-5 mb-5">
                  <div className="col-12">
                    <div className="">
                      <label className="form-label" htmlFor="email">
                        Email*
                      </label>
                      <input type="email" className="form-control" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} id="email" />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="">
                      <label className="form-label" htmlFor="address">
                        Password*
                      </label>
                      <input type="password" className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} id="address" />
                    </div>
                  </div>
                </div>

                {/* Phone Number Input */}
                <div className="row mb-5">
                  <div className="col-12">
                    <div className="">
                      <label className="form-label" htmlFor="phone_number">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
                        id="phone_number"
                      />
                    </div>
                  </div>
                </div>
                {/* Username */}
                <div className="col-12 mb-5">
                  <div className="">
                      <label className="form-label" htmlFor="username"> Handle </label>
                      <div className="input-group position-relative">
                          <span className="input-group-text" id="">coachily.com/website/</span>
                          <input
                              type="text"
                              className="form-control"
                              id="username"
                              value={username}
                              onChange={(e) => {
                                setUsername(e.target.value);
                                checkAvailability(e.target.value);
                            }}
                          />
                      </div>
                      {usernameAvailability === 'Username already taken, please choose another one.' ? (
                          <div className="mt-2 invalid-feedback d-block">{usernameAvailability}</div>
                      ) : (
                          <div className="mt-2 valid-feedback d-block">{usernameAvailability}</div>
                      )}
                  </div>
                </div>
                {/* First Name & Last Name */}
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label" htmlFor="first_name">
                        First name
                      </label>
                      <input type="text" className="form-control" placeholder="First Name" id="first_name"  value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label" htmlFor="last_name">
                        Last name
                      </label>
                      <input type="text" className="form-control" placeholder="Last Name" id="last_name" value={lastName} onChange={e => setLastName(e.target.value)}/>
                    </div>
                  </div>

                </div>

                <div className="row mb-8">
                  {/* Business Name */}
                  <div className="col-md-6">
                      <div className="">
                          <label className="form-label" htmlFor="formInputExample">
                          Business Name
                          </label>
                          <input 
                            type="text" 
                            className="form-control" 
                            id="formInputExample"
                            placeholder="Business Name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                          />
                      </div>
                  </div>

                  {/* Business Type */}
                  <div className="col-md-6">
                    <div className="">
                      <label className="form-label" htmlFor="businessType">
                      Business Type
                      </label>
                      <select 
                        className="form-select" 
                        id="businessType"
                        value={businessType}
                        onChange={e => setBusinessType(e.target.value)}
                      >
                          <option value="" disabled>Select your option</option>
                          <option value="Chess Coach">Chess Coach</option>
                          <option value="Fitness Coach">Fitness Coach</option>
                      </select>
                    </div>
                  </div>

                </div>

          
                
              
              {/* T&C checkbox */}
              {/* <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="check_primary_address"
                    id="check_primary_address"
                  />
                  <label className="form-check-label" htmlFor="check_primary_address">
                    By creating an account means you agree to the Terms and Conditions, and our Privacy Policy
                  </label>
                </div>
              </div> */}


              {/* One click signup via Google */}
              

              <div>
                <button className="btn btn-primary w-full" type="submit">
                  Save and Continue
                </button>
              </div>




              {/* <div className="py-5 text-center">
                <span className="text-xs text-uppercase font-semibold">or</span>
              </div>
              <div className="row">
                <div className="col-12">
                <button className="btn btn-primary w-full" type="button" onClick={googleSignIn}>
                    <span className="icon icon-sm pe-2">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 5C13.6168 5 15.1013 5.55353 16.2863 6.47406L19.9235 3.00409C17.8088 1.13995 15.0406 0 12 0C7.3924 0 3.39667 2.59991 1.3858 6.40985L5.43024 9.60278C6.40997 6.91937 8.97748 5 12 5Z"
                          fill="#F44336"
                        />
                        <path
                          d="M23.8961 13.5018C23.9586 13.0102 24 12.5087 24 12C24 11.1422 23.9063 10.3068 23.7352 9.5H12V14.5H18.4862C17.9615 15.8638 17.0272 17.0178 15.838 17.8195L19.8975 21.0243C22.0494 19.1354 23.522 16.4904 23.8961 13.5018Z"
                          fill="#2196F3"
                        />
                        <path
                          d="M5 12C5 11.1566 5.15686 10.3516 5.43024 9.60278L1.3858 6.40985C0.504333 8.08002 0 9.98016 0 12C0 13.9973 0.495056 15.8763 1.35822 17.533L5.40778 14.3359C5.14844 13.6044 5 12.8204 5 12Z"
                          fill="#FFC107"
                        />
                        <path
                          d="M12 19C8.95447 19 6.37042 17.0515 5.40778 14.3359L1.35822 17.533C3.35925 21.3735 7.36981 24 12 24C15.0278 24 17.7888 22.8752 19.8975 21.0243L15.838 17.8195C14.7412 18.5589 13.4284 19 12 19Z"
                          fill="#00B060"
                        />
                        <path
                          opacity="0.1"
                          d="M12 23.75C8.46832 23.75 5.29272 22.2928 3.04755 19.9713C5.24536 22.4378 8.43646 24 12 24C15.5306 24 18.6953 22.4686 20.8881 20.0408C18.6496 22.3246 15.4981 23.75 12 23.75Z"
                          fill="black"
                        />
                        <path
                          opacity="0.1"
                          d="M12 14.25V14.5H18.4862L18.5875 14.25H12Z"
                          fill="black"
                        />
                        <path
                          d="M23.9944 12.147C23.9952 12.0978 24 12.0494 24 12C24 11.986 23.9978 11.9725 23.9977 11.9586C23.9971 12.0215 23.9939 12.0838 23.9944 12.147Z"
                          fill="#E6E6E6"
                        />
                        <path
                          opacity="0.2"
                          d="M12 9.5V9.75H23.7856C23.7698 9.66748 23.7526 9.58191 23.7352 9.5H12Z"
                          fill="white"
                        />
                        <path
                          d="M23.7352 9.5H12V14.5H18.4862C17.4775 17.1216 14.9772 19 12 19C8.13403 19 5 15.866 5 12C5 8.13397 8.13403 5 12 5C13.4019 5 14.6939 5.43066 15.7885 6.14069C15.9561 6.24957 16.1289 6.35181 16.2863 6.47406L19.9235 3.00409L19.8414 2.94098C17.7369 1.11707 15.0035 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24C18.1177 24 23.1555 19.4188 23.8961 13.5018C23.9586 13.0102 24 12.5087 24 12C24 11.1422 23.9063 10.3068 23.7352 9.5Z"
                          fill="url(#paint0_linear)"
                        />
                        <path
                          opacity="0.1"
                          d="M15.7885 5.89069C14.6939 5.18066 13.4019 4.75 12 4.75C8.13403 4.75 5 7.88397 5 11.75C5 11.7922 5.00057 11.8251 5.0013 11.8672C5.06874 8.05951 8.17621 5 12 5C13.4019 5 14.6939 5.43066 15.7885 6.14069C15.9561 6.24957 16.1289 6.35181 16.2863 6.47406L19.9235 3.00409L16.2863 6.22406C16.1289 6.10181 15.9561 5.99957 15.7885 5.89069Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M12 0.25C14.975 0.25 17.6829 1.34839 19.7793 3.1416L19.9235 3.00409L19.8134 2.90827C17.709 1.08436 15.0035 0 12 0C5.37256 0 0 5.37256 0 12C0 12.0422 0.0058594 12.0829 0.0062866 12.125C0.0740356 5.55585 5.41473 0.25 12 0.25Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear"
                            x1={0}
                            y1={12}
                            x2={24}
                            y2={12}
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="white" stopOpacity="0.2" />
                            <stop offset={1} stopColor="white" stopOpacity={0} />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    Google One Click Register
                  </button>
                </div>
              </div> */}
          
              </form>

              <div className="my-6">
                <small>Already have an account? <Link to="/login" className="text-warning text-sm font-semibold">Login</Link> </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





   
  );
}

export default SignUpPage;
