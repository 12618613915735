// Profile.js
import React, { useState, useEffect } from 'react';
import { formatPhoneNumber } from '../../../utils/formValidation';

const Profile = ({ firstName, lastName, email, phoneNumber, setFirstName, setLastName, setPhoneNumber, handleSubmit }) => {
  const [initialFirstName, setInitialFirstName] = useState(firstName);
  const [initialLastName, setInitialLastName] = useState(lastName);
  const [initialPhoneNumber, setInitialPhoneNumber] = useState(phoneNumber);

  useEffect(() => {
    setInitialFirstName(firstName);
    setInitialLastName(lastName);
    setInitialPhoneNumber(phoneNumber);
  }, []);

  const handleCancel = () => {
    console.log(initialFirstName);
    console.log(firstName);
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setPhoneNumber(initialPhoneNumber);
  };

  return (
    <div className="">

      <div className="mb-5 mx-5 mt-2">
        <h5 className="mb-0">Update Profile</h5>
      </div>

      <form className="mb-6 mx-5" onSubmit={handleSubmit}>
        <div className="row mb-5">
          <div className="col-md-6">
            <div className="">
              <label className="form-label" htmlFor="first_name">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <label className="form-label" htmlFor="last_name">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-md-6">
            <div className="">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input 
                type="email" 
                className="form-control bg-light text-secondary" 
                id="email" 
                value={email}
                readOnly />
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <label className="form-label" htmlFor="phoneNumber">
                Phone number
              </label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="text-end mt-6">
          {/* <button type="button" className="btn btn-sm btn-neutral me-2" onClick={handleCancel}>
            Cancel
          </button> */}
          <button type="submit" className="btn btn-sm btn-primary">
            Save
          </button>
        </div>

      </form>
      
    </div>
  );
};

export default Profile;