import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { createUserDocument } from '../../utils/user';

function ClientRegister() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { username } = useParams(); // get the coach's username from the URL

  console.log(username);
  const navigate = useNavigate();

  const auth = getAuth(); // define the auth object
  const db = getFirestore(); // define the db object
  console.log(db);


  const register = (e) => {
    e.preventDefault();
    
  
    const q = query(collection(db, "users"), where("username", "==", username));
    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          throw new Error('No coach found with username: ' + username);
        }
  
        const coach = querySnapshot.docs[0];
        const coachUid = coach.id; // Assuming coach's UID is stored in the document
        console.log(coachUid);

          //     const coach = querySnapshot.docs[0];
  //     coachUid = coach.id;  // Storing coachUid for later use

  //     console.log(coachUid);
  
        // First, create the user
        return createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Then, create the user document
            return createUserDocument(userCredential.user, 'client', coachUid);
          })
          .then(() => {
            // Now, update the coach's document to add the new client
            const coachDocRef = doc(db, "users", coachUid);
            return updateDoc(coachDocRef, {
              clients: arrayUnion(auth.currentUser.uid)
            });
          })
          .then(() => {
            // Finally, navigate to the dashboard
            navigate(`/website/${username}/dashboard`);
          });
      })
      .catch((error) => {
        alert(error.message);
        console.error(error);
      });
  };


  // const register = (e) => {
  //   e.preventDefault();
  //   let coachUid;

  //   // here we retrieve the coach's UID based on their username
  //   const q = query(collection(db, "users"), where("username", "==", username));


  //   getDocs(q)
  //   .then((querySnapshot) => {
  //     if (querySnapshot.empty) {
  //       // handle error: there is no coach with this username
  //       console.error('No coach found with username:', username);
  //       throw new Error('No coach found with username:' + username);
  //     }
  
  //     const coach = querySnapshot.docs[0];
  //     coachUid = coach.id;  // Storing coachUid for later use

  //     console.log(coachUid);
  //   })


  //   .then(() => {
  //     // Update the coach's document to add the new client's ID to the clients array
  //     const coachDocRef = doc(db, "users", coachUid);
  //     return updateDoc(coachDocRef, {
  //       clients: arrayUnion(auth.currentUser.uid)  // Using arrayUnion to add the new client's ID
  //     });
  //   })

  //   .then(() => {
  //     return createUserWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => { 
  //       // as this page is for client registration, role is 'client'
  //       return createUserDocument(userCredential.user, 'client', coachUid);
  //     });
  //   })

  //   .then(() => {
  //     navigate(`/website/${username}/dashboard`);
  //   })
  //   .catch((error) => alert(error.message));
  // };

 

  return (
    <form onSubmit={register}>
      <label>
        Email:
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
      </label>
      <label>
        Password:
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
      </label>
      <button type="submit">Register</button>
    </form>
  );
}

export default ClientRegister;
