import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase'; // adjust path as necessary
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';

const Programs = ({ clientId }) => {
  const [clientGroups, setClientGroups] = useState([]); // Store client's current groups
  const [allGroups, setAllGroups] = useState([]); // Store all available groups (for dropdown)
  const [selectedGroup, setSelectedGroup] = useState(''); // Track selected group
  const auth = getAuth();
  const coachId = auth.currentUser.uid;

  useEffect(() => {
    fetchClientGroups();
    fetchAllGroups();
    // eslint-disable-next-line 
  }, [clientId]);

  // Fetch groups the client is part of
  const fetchClientGroups = async () => {
    const clientRef = doc(db, 'users', clientId);
    const clientDoc = await getDoc(clientRef);

    if (clientDoc.exists()) {
      const clientData = clientDoc.data();
      const groupId = clientData.fitness_group;

      if (groupId) {
        // Fetch the corresponding group name
        const groupRef = doc(db, 'fitness_group', groupId);
        const groupDoc = await getDoc(groupRef);
        
        if (groupDoc.exists()) {
          setClientGroups({ id: groupId, ...groupDoc.data() });
        }
      }
    }
  };

  // Fetch all available fitness groups
  const fetchAllGroups = async () => {
    const groupsSnapshot = await getDocs(collection(db, 'fitness_group'));
    const groupsArray = groupsSnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(group => group.coachId === coachId);
    setAllGroups(groupsArray);
  };

  // Handle group selection from dropdown
  const handleGroupChange = async (e) => {
    const newGroupId = e.target.value;
    setSelectedGroup(newGroupId);

    // Remove client from their current group and add to the new group
    const clientRef = doc(db, 'users', clientId);

    // Get the old group(s)
    const clientDoc = await getDoc(clientRef);
    const clientData = clientDoc.data();
    const oldGroupId = clientData.fitness_group;
    console.log(`Old groups: ${oldGroupId}`);

    try {
      // Remove from old group(s) and update the new group
      if (oldGroupId) {
        const oldGroupRef = doc(db, 'fitness_group', oldGroupId);
        await updateDoc(oldGroupRef, {
          members: arrayRemove(clientId)
        });
      }

      // Add to the new group
      const newGroupRef = doc(db, 'fitness_group', newGroupId);
      await updateDoc(newGroupRef, {
        members: arrayUnion(clientId)
      });

      // Update the client's document to reflect the new group
      await updateDoc(clientRef, {
        fitness_group: newGroupId
      });

      // Refresh the groups
      fetchClientGroups();
    } catch (error) {
      console.error('Error updating group assignment:', error);
    }
  };

  return (
    <div className="">
      <div className="card-header border-bottom d-flex align-items-center">
        <h5 className="me-auto h3">Group Programs</h5>
      </div>

      {/* Display client's current groups */}
      <div className="card-body">
        <h6 className="d-inline">Current Program:   </h6>
        {clientGroups ? (
              <Link to={`/dashboard/groups/${clientGroups.id}`}>
                {clientGroups.name === "summer shred" ? "Summer Shred" : clientGroups.name}
              </Link>
        ) : (
          <p>No group programs assigned.</p>
        )}

        {/* Dropdown for changing the client's group */}
        <div className="mt-3">
          <h6 htmlFor="groupDropdown">Move to a different group:</h6>
          <select
            id="groupDropdown"
            className="form-select"
            value={selectedGroup}
            onChange={handleGroupChange}
          >
            <option value="">Select a group...</option>
            {allGroups.map(group => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Programs;
