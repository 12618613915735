import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PersonalWebsitePage from './components/clientlanding/CoachWebsitePage';
import { useAuth } from './hooks/useAuth';
import { ProtectedRoute } from './utils/ProtectedRoute';

// Analytics
import { useEffect } from 'react';
import { initializeAnalytics, trackPageView } from './utils/analytics'; // Import the functions


// Coach pages
import SignUpPage from './components/coachlanding/SignUpPage';
import HomePage from './components/coachlanding/HomePage';
import LoginPage from './components/coachlanding/LoginPage';

import Home from './components/coachportal/Home';
import WebsiteBuilder from './components/coachportal/WebsiteBuilder';
import Clients from './components/coachportal/Clients';
import ClientDetails from './components/coachportal/Clients/ClientDetails';
import Account from './components/coachportal/Account';
import Payments from './components/coachportal/Payments';
import ProgramGroupsList from './components/coachportal/ProgramGroups/ProgramGroupsList';
import GroupsDetails from './components/coachportal/ProgramGroups/GroupsDetails';
import CoachChat from './components/coachportal/Chat';

import CreateWorkout from './components/coachportal/Clients/Workouts/CreateWorkout'; // adjust path as necessary
import EditWorkout from './components/coachportal/Clients/Workouts/EditWorkout';


// Client pages
import ClientRegister from './components/clientlanding/ClientPortal_RegisterPage';
import ClientLogin from "./components/clientlanding/ClientPortal_LoginPage";

import ClientHome from './components/clientportal/Home';
import Scheduling from './components/clientportal/ClientPortal_SchedulingPage';
import Program from './components/clientportal/Program';
import Chat from './components/clientportal/chat/Chat';
import ClientBilling from './components/clientportal/ClientPortal_BillingPage';
import ClientAccount from './components/clientportal/accountTabs/Main';

import WorkoutPreview from './components/clientportal/WorkoutPreview';
import WorkoutStart from './components/clientportal/WorkoutStart';



// import the styles
import './scss/main.scss';
import './scss/utility.scss';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
      initializeAnalytics(); // Initialize Google Analytics once
      trackPageView(location.pathname); // Track the initial page load
  }, []);

  useEffect(() => {
      trackPageView(location.pathname); // Track page views on route changes
  }, [location]);

  return null;
}

function App() {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <AnalyticsTracker />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route path="/website/:username/register" element={<ClientRegister />} />
        <Route path="/website/:username/login" element={<ClientLogin />} />
        <Route path="/website/:username" element={<PersonalWebsitePage />} />

        <Route path="/website/:username/dashboard" element={<ClientHome />} />
        <Route path="/website/:username/scheduling" element={<Scheduling />} />
        <Route path="/website/:username/program" element={<Program />} />
        <Route path="/website/:username/billing" element={<ClientBilling />} />
        <Route path="/website/:username/account" element={<ClientAccount />} />
        <Route path="/website/:username/chat" element={<Chat />} />
        <Route path="/website/:username/workout-preview/:workoutId" element={<ProtectedRoute><WorkoutPreview /></ProtectedRoute>} />
        <Route path="/website/:username/workout-start/:workoutId" element={<ProtectedRoute><WorkoutStart /></ProtectedRoute>} />


        <Route path="/dashboard" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/dashboard/website" element={<ProtectedRoute><WebsiteBuilder /></ProtectedRoute>} />
        <Route path="/dashboard/clients" element={<ProtectedRoute><Clients /></ProtectedRoute>} />
        <Route path="dashboard/clients/:id" element={<ProtectedRoute><ClientDetails /></ProtectedRoute>} />
        <Route path="/dashboard/groups" element={<ProtectedRoute><ProgramGroupsList /></ProtectedRoute>} />
        <Route path="/dashboard/groups/:groupId" element={<ProtectedRoute><GroupsDetails /></ProtectedRoute>} />
        <Route path="/dashboard/account/profile" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path="/dashboard/account/payments" element={<ProtectedRoute><Payments /></ProtectedRoute>} />
        <Route path="/dashboard/chat" element={<ProtectedRoute><CoachChat /></ProtectedRoute>} />

        <Route path="/dashboard/groups/:groupId/create-workout" element={<ProtectedRoute><CreateWorkout /></ProtectedRoute>}  />
        <Route path="/dashboard/groups/:groupId/edit-workout/:workoutId" element={<ProtectedRoute><EditWorkout /></ProtectedRoute>} />

        <Route path="/dashboard/clients/:id/create-workout" element={<ProtectedRoute><CreateWorkout /></ProtectedRoute>}  />
        <Route path="/dashboard/clients/:id/edit-workout/:workoutId" element={<ProtectedRoute><EditWorkout /></ProtectedRoute>} />





      </Routes>
    </Router>
  );
}

export default App;
