// WorkoutStart.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import ClientNavbar from '../../layouts/ClientNavbar';

const WorkoutStart = () => {
    const { workoutId } = useParams();
    const [workout, setWorkout] = useState(null);
    const [expandedExercise, setExpandedExercise] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWorkout = async () => {
            const workoutRef = doc(db, 'assigned_workouts', workoutId);
            const workoutSnap = await getDoc(workoutRef);

            if (workoutSnap.exists()) {
                const workoutData = workoutSnap.data();
                // Initialize completedSets if not present, autofill with existing reps and weight
                workoutData.exercises = workoutData.exercises.map(exercise => ({
                    ...exercise,
                    completedSets: exercise.completedSets || Array(exercise.sets).fill().map((_, idx) => ({
                        reps: exercise.reps,
                        weight: exercise.weight,
                        status: 'incomplete'
                    }))
                }));
                setWorkout(workoutData);
            } else {
                console.log('No such document!');
            }
        };

        fetchWorkout();
    }, [workoutId]);

    // Debounce function to limit the frequency of updates
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const saveWorkout = debounce(async (updatedWorkout) => {
        const workoutRef = doc(db, 'assigned_workouts', workoutId);
        try {
            await updateDoc(workoutRef, updatedWorkout);
        } catch (error) {
            console.error('Error saving workout:', error);
        }
    }, 2000); // Adjust the debounce delay as needed

    useEffect(() => {
        if (workout) {
            saveWorkout(workout);
        }
    }, [workout]);

    const handleExpandExercise = (index) => {
        setExpandedExercise(expandedExercise === index ? null : index);
    };

    const handleChange = (exerciseIndex, setIndex, field, value) => {
        const updatedExercises = workout.exercises.map((exercise, i) => {
            if (i === exerciseIndex) {
                const updatedCompletedSets = exercise.completedSets.map((set, j) => {
                    if (j === setIndex) {
                        return { ...set, [field]: value };
                    }
                    return set;
                });
                return { ...exercise, completedSets: updatedCompletedSets };
            }
            return exercise;
        });
        setWorkout({ ...workout, exercises: updatedExercises });
    };

    const handleComplete = async () => {
        const workoutRef = doc(db, 'assigned_workouts', workoutId);
        try {
            await updateDoc(workoutRef, { ...workout, state: 'complete' });
            navigate('/website/:username/dashboard'); // navigate to a different page or show a success message
        } catch (error) {
            console.error('Error updating workout: ', error);
        }
    };

    // Function to navigate directly to the video link
    const handleVideoLinkClick = (videoLink) => {
        if (videoLink.startsWith("http")) {
            window.open(videoLink, "_blank");  // Opens in a new tab
        } else {
            // Handle malformed URLs or forcefully prefix http://
            window.open(`http://${videoLink}`, "_blank");
        }
    };

    if (!workout) {
        return <p>Loading...</p>;
    }

    return (
        <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
            <ClientNavbar />
            <div className="h-screen flex-grow-1 overflow-y-lg-auto">
                <header className="bg-surface-primary border-bottom pt-6 sticky-top">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="h2 mb-0 ls-tight">{workout.name}</h1>
                            <button className="btn btn-light" onClick={() => navigate(-1)}>X</button>
                        </div>
                    </div>
                </header>

                <div className="card rounded-0">
                    <div className="card-body">
                        <div className="list-group list-group-flush">
                            {workout.exercises.map((exercise, index) => (
                                <div key={index} className="list-group-item px-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0">{exercise.name}</h5>
                                        {exercise.videoLink && (
                                            <i
                                                className="fa-regular fa-clapperboard-play ms-2"
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                onClick={() => handleVideoLinkClick(exercise.videoLink)}
                                            />
                                        )}
                                        <div className="ms-auto">
                                            <button className="btn btn-link" onClick={() => handleExpandExercise(index)}>
                                                {expandedExercise === index ? 'Collapse' : 'Expand'}
                                            </button>
                                        </div>
                                    </div>
                                    {expandedExercise === index && (
                                        <div className="table-responsive">
                                            <table className="table table-striped mt-3">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ padding: '5px' }}>Set</th>
                                                        <th scope="col" style={{minWidth: '110px'}}>{exercise.unitType.charAt(0).toUpperCase() + exercise.unitType.slice(1)}</th>
                                                        <th scope="col" style={{minWidth: '110px'}}>Weight</th>
                                                        <th scope="col">State</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {exercise.completedSets.map((set, setIndex) => (
                                                        <tr key={setIndex}>
                                                            <td style={{ padding: '5px' }}>{setIndex + 1}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={set.reps}
                                                                    onChange={(e) => handleChange(index, setIndex, 'reps', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={set.weight}
                                                                    onChange={(e) => handleChange(index, setIndex, 'weight', e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={set.status === 'complete'}
                                                                    onChange={(e) => handleChange(index, setIndex, 'status', e.target.checked ? 'complete' : 'incomplete')}
                                                                />
                                                                {set.status === 'complete' && (
                                                                    <i className="bi bi-check-circle-fill text-success ms-2"></i>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            ))}
                        
                            <div className="text-center mt-4">
                                <button className="btn btn-primary" onClick={handleComplete}>Complete Workout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkoutStart;
