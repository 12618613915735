// Home.js
import React, { useState, useEffect } from 'react';
import { auth} from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import ClientNavbar from '../../layouts/ClientNavbar';
import { getUserAppointments } from '../../utils/appointments'; 
import { db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';


const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};


const Scheduling = () => {
  const { username } = useParams()
  const [appointments, setAppointments] = useState([]);
  const [filter, setFilter] = useState('Upcoming');

  useEffect(() => {
    const fetchData = async () => {
      const currentUserId = auth.currentUser.uid; // Replace with actual logic to get the current user ID
      const userAppointments = await getUserAppointments(currentUserId);
  
      const appointmentsWithCoachName = await Promise.all(userAppointments.map(async (appointment) => {
        let coachFirstName = await getCoachFirstName(appointment.coachId);
        coachFirstName = capitalizeFirstLetter(coachFirstName); // Capitalize the first letter

        return {
          ...appointment,
          coachFirstName,
        };
      }));

      // console.log('apporintmentsWithCoachName:', appointmentsWithCoachName);
  
      setAppointments(appointmentsWithCoachName);
    };
  
    fetchData();
  }, []);

  const filterAppointments = (appointments, filter) => {
  
    return appointments.filter(appointment => {
      const appointmentDateTime = appointment.appointmentDateTime
      
      const currentDate = new Date().toISOString();

      // console.log("Current Date:", currentDate);
      // console.log("Appointment Date Time:", appointmentDateTime);
      // console.log( appointmentDateTime > currentDate)
  
      switch (filter) {
        case 'Upcoming':
          // Check if the appointment date and time are after the current date and time
          return (appointmentDateTime > currentDate && !appointment.isCancelled);
        case 'Completed':
          // Check if the appointment date and time are before the current date and time
          return appointmentDateTime < currentDate && !appointment.isCancelled;
        case 'All active':
          // Check if the appointment date and time are before the current date and time
          return !appointment.isCancelled;
        case 'Cancelled':
          // Check if the appointment date and time are before the current date and time
          return appointment.isCancelled;
        // Add more cases as per your requirements
        default:
          return true;
      }
    });
  };

  const handleFilterChange = (selectedFilter) => {
      setFilter(selectedFilter);
      // The filtering logic can be applied here or in the ClientAppointmentsTable component/data source.
  };

  // Placeholder function - replace with actual logic
  const getCoachFirstName = async (coachId) => {
    if (!coachId) {
      console.error('No coach ID provided');
      return '';
    }
  
    try {
      const coachRef = doc(db, 'users', coachId);
      const coachSnap = await getDoc(coachRef);
  
      if (coachSnap.exists()) {
        const coachData = coachSnap.data();

        return coachData.firstName || ''; // Assuming the field is named 'firstName'
      } else {
        console.error('Coach not found');
        return '';
      }
    } catch (error) {
      console.error('Error fetching coach data:', error);
      return '';
    }
  };
  

  return (
  <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">

    {/* Vertical Navbar */}
    <ClientNavbar username={username} />

    {/* Main content */}
    <div className="h-screen flex-grow-1 overflow-y-lg-auto">
      <header className="bg-surface-primary border-bottom pt-6 sticky-top">
        <div className="container-fluid">
          <div className="mb-npx">
            <div className="row align-items-center justify-content-between">
              <div className="col mb-4 mb-sm-0">
                {/* Title */}
                <h1 className="h2 mb-0 ls-tight">Schedule</h1>
              </div>


              {/* Dropdown */}
              <div className="col-auto"> 
                <div className="dropdown">
                  <button className="btn btn-sm btn-neutral dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      {filter}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Upcoming'); }}>Upcoming</a></li>
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Completed'); }}>Completed</a></li>
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('All active'); }}>All active</a></li>
                      <li><a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); handleFilterChange('Cancelled'); }}>Cancelled</a></li>
                  </ul>
                  </div>
                {/* <button type="button" className="btn btn-primary">+</button>  */}
              </div>
            </div>
            {/* Nav */}
            <ul className="nav nav-tabs mt-4 overflow-x border-0"></ul>
          </div>
        </div>
      </header>

      <div className="card rounded-0">
        <div className="card-body">
          <div className="list-group list-group-flush">

          {filterAppointments(appointments, filter).length > 0 ? (
            filterAppointments(appointments, filter).map((appointment, index) => {
              const appointmentDate = new Date(appointment.appointmentDateTime);

              const dayOfWeek = appointmentDate.toLocaleDateString('en-US', { weekday: 'short' });
              const dateFormatted = appointmentDate.toLocaleDateString();

              // Capitalize appointment type and fetch coach's first name
              const appointmentTypeCapitalized = capitalizeFirstLetter(appointment.type);

              // Construct the title
              const appointmentTitle = `${appointmentTypeCapitalized} Training with ${appointment.coachFirstName}`;

              // Create and format Start Time
              const startTimeFormatted = appointmentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

              // Create and format End Time (one hour later)
              const endTime = new Date(appointmentDate);
              endTime.setHours(endTime.getHours() + 1);
              const endTimeFormatted = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

              return (
                <div key={index} className="list-group-item d-flex align-items-center px-0">
                  {/* Date Section */}
                  <div className="d-flex align-items-start me-4">
                    <div className="text-center" style={{ width: '80px' }}>
                      <span className="d-block h6 font-semibold">{dayOfWeek}</span>
                      <span className="d-block h6 font-semibold">{dateFormatted}</span>
                    </div>
                  </div>
                    {/* Appointment Details */}
                  <div className="flex-fill" style={{ borderLeft: '1px solid #ddd', paddingLeft: '20px' }}>
                    <span className="d-block text-sm text-muted mb-2">{appointment.portal_notes || ''}</span>
                    <a className="d-block h3 font-semibold mb-3">{appointmentTitle}</a>
                    <span className="d-block text-sm text-muted font-semibold mb-2">🕛&nbsp;&nbsp;  {startTimeFormatted} - {endTimeFormatted}</span> 
                    {/* <span className="d-block text-sm text-muted font-semibold mb-1">📍 {appointment.location}</span> */}
                  </div>
                  {/* Dropdown Menu */}
                  {/* ... dropdown menu code ... */}
                </div>
              )
            })
          ) : (
            <div className="list-group-item d-flex align-items-center px-0">
                  <div className="flex-fill text-center">
                    <p>No upcoming appointments</p>
                  </div>
                </div>
          )}
          </div>
        </div>
      </div>

    </div>

  </div>
  );
}

export default Scheduling;
