import React, { useState, useEffect } from 'react';
import { cancelUserAppointment, getUserAppointments } from '../../../utils/appointments';

const ClientAppointmentsTable = ({ userId, clientId, type, filter}) => {
    const [appointments, setAppointments] = useState([]);
    const [error, setError] = useState(null);  // To display error messages.

    console.log("Component mounting or updating");

    const fetchData = async () => {
        console.log("Fetching data");

        const data = await getUserAppointments(clientId);
        let filteredData = data;

        const currentDate = new Date();
        currentDate.setSeconds(0, 0); // Reset seconds and milliseconds.
        currentDate.toISOString();

        switch(filter) {
            case "Upcoming":
                filteredData = data.filter(appointment => 
                    new Date(appointment.appointmentDateTime) > currentDate && !appointment.isCancelled && appointment.appointmentDateTime);
                break;
            case "Completed":
                filteredData = data.filter(appointment => 
                    new Date(appointment.appointmentDateTime) < currentDate && !appointment.isCancelled && appointment.appointmentDateTime);
                break;
            case "All active":
                filteredData = data.filter(appointment => !appointment.isCancelled && appointment.appointmentDateTime);
                break;
            case "Cancelled":
                filteredData = data.filter(appointment => appointment.isCancelled && appointment.appointmentDateTime);
                break;
            default:
                break;
        }

        setAppointments(filteredData);
    };

    useEffect(() => {
        fetchData();
    }, [clientId, filter]);

    const handleCancel = async (userId, appointment) => {
        const appointmentDate = new Date(appointment.appointmentDateTime);

        const currentDate = new Date()
        currentDate.setSeconds(0, 0); // Reset seconds and milliseconds.
        currentDate.toISOString();
        
        if (appointmentDate < currentDate) {
            setError("You cannot cancel past appointments.");
            return;
        }

        await cancelUserAppointment(userId, appointment.id);
        setError(null);  // Clear any previous errors.
        fetchData();  // Re-fetch the data after cancelling.
    }

    return (
        <div className="table-responsive">
            {error && <p className="text-danger">{error}</p>}  {/* Display error messages */}
            <table className="table table-hover table-nowrap">
                <thead className="table-light">
                    <tr>
                        <th scope="col">Appointment Date</th>
                        <th scope="col">Appointment Time</th>
                        <th scope="col">Appointment Type</th>
                        <th scope="col">Session Name</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                {appointments.map(appointment => (
                    <tr key={appointment.id}>
                        <td>  {new Date(appointment.appointmentDateTime).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}</td>

                        <td>
                            {new Date(appointment.appointmentDateTime).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true
                            })}
                        </td>
                        <td>{appointment.type}</td>
                        <td>
                            {appointment.portal_notes === "" ? "No name listed" : appointment.portal_notes}
                        </td>
                        <td>
                            <button onClick={() => handleCancel(userId, appointment)}>Cancel</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default ClientAppointmentsTable;
