import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logo.png';


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { username } = useParams(); // get the coach's username from the URL
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password);

    // Navigate to the client's dashboard
    navigate(`/website/${username}/dashboard`);
  }


  return (
      <div className="px-5 py-5 p-lg-0 bg-surface-secondary">
        <div className="d-flex justify-content-center">
          <div className="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
            {/* Logo */}
            <a className="d-block" href="/">
              <img
                src={logo}
                className="h-10"
                alt="..."
              />
            </a>
            {/* Title */}
            <div className="mt-32 mb-20">
              <h1 className="ls-tight font-bolder display-6 text-white mb-5">
                Let's have an amazing day! 
              </h1>
              <p className="text-white text-opacity-75">
                Login to access your dashboard
              </p>
            </div>
            {/* Circle */}
            <div className="w-56 h-56 bg-orange-500 rounded-circle position-absolute bottom-0 end-20 transform translate-y-1/3" />
          </div>
          <div className="col-12 col-md-9 col-lg-7 offset-lg-5 border-left-lg min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
            <div className="row">
              <div className="col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0">
                <div className="mt-10 mt-lg-5 mb-6 d-flex align-items-center d-lg-block">
                  <span className="d-inline-block d-lg-block h1 mb-lg-6 me-3">
                  👋&nbsp;&nbsp;&nbsp;Hello&nbsp;
                  </span>
                  <h1 className="ls-tight font-bolder h2">Nice to see you!</h1>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="mb-5">
                    <label className="form-label" htmlFor="email">
                      Email address
                    </label>
                    <input type="email" className="form-control form-control-muted" id="email" value={email} onChange={e => setEmail(e.target.value)} />
                  </div>
                  <div className="mb-5">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <input type="password" className="form-control form-control-muted" id="password" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
                  </div>
                  {/* <div className="mb-5">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="check_example"
                        id="check_example"
                      />
                      <label className="form-check-label" htmlFor="check_example">
                        Keep me logged in
                      </label>
                    </div>
                  </div> */}
                  <div>
                    <button type="submit" className="btn btn-primary w-full">
                      Sign in
                    </button>
                  </div>
                </form>
                <div className="my-6">
                  <small>Don't have an account?</small>
                  <a href="/register" className="text-warning text-sm font-semibold">
                    Sign up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }



//     <form onSubmit={handleLogin}> 
//       <label>
//         Email:
//         <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
//       </label>
//       <label>
//         Password:
//         <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
//       </label>
//       <button type="submit">Login</button>
//     </form>
//   );
// }

export default Login;
