import { doc,  getDoc, runTransaction, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';

export const createAppointment = async (clientUserId, coachId, type, appointmentData) => {
  const clientUserRef = doc(db, 'users', clientUserId);
  const coachUserRef = doc(db, 'users', coachId);
  
  const date = new Date(`${appointmentData.date}T${appointmentData.time}:00`);



  const newAppointment = {
    clientIds: [{ id: clientUserId, isCancelled: false }],
    coachId: coachId,
    duration: appointmentData.duration || "", 
    portal_notes: appointmentData.notes || "", 
    public_notes: appointmentData.public_notes || "", 
    location_type: appointmentData.location_type || "", 
    location_address: appointmentData.location_address || "", 
    type: type || "", 
    max_clients: appointmentData.max_clients || 1, // Default to 1 for individual classes
    isCancelled: false, // NotE: This ONLY captures coach cancellations - client cancellations will be captured in the clientIds.
    isDeleted: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    currentCount: 1,
    appointmentDateTime: date.toISOString(),
  };
  
  try {
    await runTransaction(db, async (transaction) => {
      const clientUserSnapshot = await transaction.get(clientUserRef);
      const coachUserSnapshot = await transaction.get(coachUserRef);

      if (!clientUserSnapshot.exists()) {
        throw new Error("Client User not found!");
      }

      if (!coachUserSnapshot.exists()) {
        throw new Error ("Coach User not found!");
      }


      // Add the new appointment to the appointments collection
      const appointmentRef = await addDoc(collection(db, 'appointments'), newAppointment);

      // Get the current list of appointments for the client and coach users
      const clientAppointments = clientUserSnapshot.data().appointments;
      const coachAppointments = coachUserSnapshot.data().appointments;

      // Add the new appointment to the users' lists
      clientAppointments.push(appointmentRef.id); 
      coachAppointments.push(appointmentRef.id);

      // Update the user's appointments list
      transaction.update(clientUserRef, { appointments: clientAppointments });
      transaction.update(coachUserRef, { appointments: coachAppointments });
    });
  } catch (e) {
    console.log('Transaction failure:', e);
  }
}


export const cancelUserAppointment = async (userId, appointmentId) => {
  // Document references
  const appointmentRef = doc(db, 'appointments', appointmentId);
  const userRef = doc(db, 'users', userId);

  console.log("appointments.js cancel - userID", userId);
  console.log("appointments.js cancel - userRed", userRef);

  // Run the transaction
  try {
      await runTransaction(db, async (transaction) => {
          const appointmentDoc = await transaction.get(appointmentRef);
          const userDoc = await transaction.get(userRef);

          if (!appointmentDoc.exists() || !userDoc.exists()) {
              throw new Error("User or appointment not found!");
          }

          const appointmentData = appointmentDoc.data();
          const userType = userDoc.data().role;


          console.log("appointments.js cancel - coachId", appointmentData.coachId);

          // Coach cancellation
          if (userType === 'coach' && appointmentData.coachId === userId) {
              console.log("This scenario!")
              transaction.update(appointmentRef, { isCancelled: true });
          } 
          // Client cancellation
          else if (userType === 'client') {
              const clientIndex = appointmentData.clientIds.findIndex(client => client.id === userId);
              if (clientIndex > -1) {
                  // Update the specific client's 'isCancelled' status
                  const updatedClient = { ...appointmentData.clientIds[clientIndex], isCancelled: true };
                  // Create a new array with the updated client object
                  const updatedClientIds = [
                      ...appointmentData.clientIds.slice(0, clientIndex),
                      updatedClient,
                      ...appointmentData.clientIds.slice(clientIndex + 1)
                  ];
                  transaction.update(appointmentRef, { clientIds: updatedClientIds });
              }
          } else {
              throw new Error("User type not valid or user is not part of this appointment!");
          }
      });
  } catch (e) {
      console.log('Transaction failure:', e);
  }
}

export const getUserAppointments = async (userId) => {
    
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
      console.error("User not found");
      return [];
  }

  const appointmentsIds = userDoc.data().appointments;
  const appointments = [];


  for (let appointmentId of appointmentsIds) {
      if (appointmentId.trim() === "") continue;
      const appointmentRef = doc(db, 'appointments', appointmentId);
      const appointmentDoc = await getDoc(appointmentRef);
      if (appointmentDoc.exists()) {
          const appointmentData = appointmentDoc.data();

          // Consolidate status
          let status = "Active";
          if (appointmentData.isCancelled) {
              status = "Cancelled";
          } else if (appointmentData.type === "private" && appointmentData.clientIds.find(client => client.id === userId)?.isCancelled) {
              status = "Cancelled";
          } else if (appointmentData.type === "group" && appointmentData.clientIds.length === 0) {
              status = "Pending";
          }

          appointments.push({
              ...appointmentData,
              id: appointmentDoc.id,
              status,
          });
      }
  }
  
  return appointments;
}

export const getUserAppointmentsWithClientInfo = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    console.error("User not found");
    return [];
  }

  const appointmentsIds = userDoc.data().appointments;
  const appointmentsWithClients = [];

  for (let appointmentId of appointmentsIds) {
    if (appointmentId.trim() === "") continue;
    const appointmentRef = doc(db, 'appointments', appointmentId);
    const appointmentDoc = await getDoc(appointmentRef);

    if (appointmentDoc.exists()) {
      const appointmentData = appointmentDoc.data();
      const clientsInfo = [];

      for (const client of appointmentData.clientIds) {
        const clientRef = doc(db, 'users', client.id);
        const clientDoc = await getDoc(clientRef);

        if (clientDoc.exists()) {
          const { firstName, lastName } = clientDoc.data();
          clientsInfo.push({ id: client.id, firstName, lastName, isCancelled: client.isCancelled });
        }
      }

      // Consolidate status
      let status = "Active";
      if (appointmentData.isCancelled) {
        status = "Cancelled";
      } else if (appointmentData.type === "private" && clientsInfo.find(client => client.id === userId)?.isCancelled) {
        status = "Cancelled";
      } else if (appointmentData.type === "group" && clientsInfo.length === 0) {
        status = "Pending";
      }

      // Construct the "name" field
      let name = "";
      if (appointmentData.type === "private") {
        const activeClients = clientsInfo.filter(client => !client.isCancelled);
        if (activeClients.length === 1) {
          const client = activeClients[0];
          name = `Private with ${client.firstName} ${client.lastName}`;
        } else if (activeClients.length > 1) {
          const firstClient = activeClients[0];
          name = `Private with ${firstClient.firstName} ${firstClient.lastName} + ${activeClients.length - 1}`;
        }
      } else if (appointmentData.type === "group") {
        const activeClients = clientsInfo.filter(client => !client.isCancelled).length;
        name = `${appointmentData.portal_notes || ""} (${activeClients}/${appointmentData.max_clients} spots filled)`;
      }
            
      appointmentsWithClients.push({
        ...appointmentData,
        id: appointmentDoc.id,
        status,
        clients: clientsInfo, 
        name
      });
    }
  }

  return appointmentsWithClients;
};