import React from 'react';
import Sidebar from '../../layouts/Coachportal_sidebar';


const Payments = () => {
  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />

      {/* Main content */}
      <div className="h-screen flex-grow-1 overflow-y-lg-auto">


        

        {/* Main */}
        <main className="py-6 bg-surface-secondary">

        </main>
      </div>
    </div>
  );
};

export default Payments;