import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase'; // Adjust the path as necessary
import { useParams, Link } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import ClientNavbar from '../../layouts/ClientNavbar';
import { getAuth } from 'firebase/auth';

const Programs = () => {
  const [workouts, setWorkouts] = useState([]);
  const [fitnessGroupId, setFitnessGroupId] = useState(null); // Define the fitnessGroupId state
  const { username } = useParams(); // Ensure this name matches the route parameter
  const auth = getAuth();
  const clientId = auth.currentUser?.uid; // Get the currently logged-in user's ID

  useEffect(() => {
    if (!clientId) {
      console.error('Client ID is undefined');
      return;
    }

    const fetchUserFitnessGroup = async () => {
      try {
        // Fetch the user's fitness group from Firestore
        const userRef = doc(db, 'users', clientId);  // Ensure `doc` is imported and used here
        const userSnapshot = await getDoc(userRef);
        
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setFitnessGroupId(userData.fitness_group); // Set the fitness group ID
        }
      } catch (error) {
        console.error('Error fetching user fitness group:', error);
      }
    };

    fetchUserFitnessGroup();
  }, [clientId]);

  useEffect(() => {
    console.log("fetching fitness group");

    if (!fitnessGroupId) {
      console.log("no fitness group")
      return; // If no fitness group, do nothing
    }

    const fetchWorkouts = async () => {
      console.log("we made it here")
      const today = new Date();
      const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      const fourteenDaysAhead = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);

      const workoutsRef = collection(db, "assigned_workouts");
      const q = query(
        workoutsRef,
        where("group", "==", fitnessGroupId), // Filter by fitness group
        where("dateAssigned", ">=", sevenDaysAgo.toISOString().split('T')[0]),
        where("dateAssigned", "<=", fourteenDaysAhead.toISOString().split('T')[0]),
        orderBy("dateAssigned")
      );
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot);

      const fetchedWorkouts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        dateAssigned: doc.data().dateAssigned, // Now a string, no need to convert
      }));
      setWorkouts(fetchedWorkouts);
    };

    fetchWorkouts();
  }, [fitnessGroupId]);

  return (
    <div className="d-flex flex-column flex-md-row h-lg-full bg-surface-secondary">
      {/* Vertical Navbar */}
      <ClientNavbar username={username} />

      <div className="h-screen flex-grow-1 overflow-y-lg-auto">
        <header className="bg-surface-primary border-bottom py-4 sticky-top">
          <div className="container-fluid">
            <div className="mb-npx">
              <div className="row align-items-center justify-content-between">
                <div className="col mb-4 mb-sm-0">
                  <h1 className="h2 mb-0 ls-tight">Client Workouts</h1>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="">
          <div className="">
            <div className="container-fluid bg-white ">
              <div className="list-group list-group-flush mx-5">
                {workouts.length > 0 ? (
                  workouts.map((workout) => (
                    <Link to={`/website/${username}/workout-preview/${workout.id}`} className="list-group-item px-0" key={workout.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="h5 mb-0">
                          {workout.name}
                        </div>
                        <span className="text-muted">
                          {workout.dateAssigned}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        {workout.duration === "" ? (
                          <span></span>
                        ) : (
                          <span>
                            <i className="bi bi-clock me-2"></i>{workout.duration} minutes
                          </span>
                        )}
                        <span className="badge bg-primary">
                          {workout.difficulty.charAt(0).toUpperCase() + workout.difficulty.slice(1)}
                        </span>
                      </div>
                    </Link>
                  ))
                ) : (
                  <p>No workouts assigned yet.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
