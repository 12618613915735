import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAwiUua_hk7iNnsqn_mGNZcDFVZd8dL-jE",
  authDomain: "coachcloud-73254.firebaseapp.com",
  projectId: "coachcloud-73254",
  storageBucket: "coachcloud-73254.appspot.com",
  messagingSenderId: "642947325858",
  appId: "1:642947325858:web:b06aaed604b3f9a0f5dcfb",
  measurementId: "G-JH7NH4DD93"
};
  

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
export const auth = getAuth(app);