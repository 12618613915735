import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const AddClients = ({ groupId }) => {
  const [participatingClients, setParticipatingClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]); 

  useEffect(() => {
    fetchGroupClients();  // Fetch clients in the group (based on members array)
    // eslint-disable-next-line 
  }, [groupId]);

  // Fetch clients from the fitness group based on members array
  const fetchGroupClients = async () => {
    try {
      const groupRef = doc(db, 'fitness_group', groupId);  // Reference to the group document
      const groupSnapshot = await getDoc(groupRef);
      if (groupSnapshot.exists()) {
        const groupData = groupSnapshot.data();
        const members = groupData.members || [];  // The members array with client IDs

        const clients = [];
        // Fetch each client's details based on their ID in the members array
        for (const memberId of members) {
          const clientRef = doc(db, 'users', memberId);
          const clientSnapshot = await getDoc(clientRef);
          if (clientSnapshot.exists()) {
            clients.push({ id: clientSnapshot.id, ...clientSnapshot.data() });
          }
        }
        setParticipatingClients(clients);  // Update state with the participating clients
        fetchAllClients(clients); // Call fetchAllClients only after participatingClients is set
      }
    } catch (error) {
      console.error('Error fetching group clients:', error);
    }
  };

  // Fetch all available clients to add to the group
  const fetchAllClients = async (participatingClients) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error('No authenticated user found');
        return;
      }

      const q = query(
        collection(db, 'users'),
        where('role', '==', 'client'),    // Fetch only users with role = 'client'
        where('coachUid', '==', user.uid) // Fetch only clients that belong to the current coach
      );

      const querySnapshot = await getDocs(q);
      const clientsArray = [];

      querySnapshot.forEach((doc) => {
        const clientData = doc.data();
        if (!clientData.deleted_at) {
          clientsArray.push({ id: doc.id, ...clientData });
        }
      });

      // Filter out clients that are already in the participating list
      const filtered = clientsArray.filter(client => !participatingClients.some(participant => participant.id === client.id));

      setFilteredClients(filtered);  // Update state with non-participating clients
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  // Function to add a client to the group
  const addClientToGroup = async (clientId) => {
    try {
      // 1. Update the fitness group document by adding the client to the 'members' array
      const groupRef = doc(db, 'fitness_group', groupId);
      await updateDoc(groupRef, {
        members: arrayUnion(clientId),
      });

      // 2. Update the client document by adding the groupId to the 'fitness_groups' array
      const clientRef = doc(db, 'users', clientId);
      await updateDoc(clientRef, {
        fitness_group: groupId,
      });

      // After adding the client, refetch the data to update the UI
      fetchGroupClients();
    } catch (error) {
      console.error('Error adding client to group:', error);
    }
  };

  // Function to remove a client from the group
  const removeClientFromGroup = async (clientId) => {
    try {
      // 1. Remove the client from the 'members' array in the fitness group
      const groupRef = doc(db, 'fitness_group', groupId);
      await updateDoc(groupRef, {
        members: arrayRemove(clientId),
      });

      // 2. Remove the groupId from the 'fitness_groups' array in the client document
      const clientRef = doc(db, 'users', clientId);
      await updateDoc(clientRef, {
        fitness_group: "",
      });

      // After removing the client, refetch the data to update the UI
      fetchGroupClients();
    } catch (error) {
      console.error('Error removing client from group:', error);
    }
  };

  return (
    <div className="card pt-3 m-4 rounded-0">
      <div className="card-body">
        <h5 className="mb-3">Clients Participating in This Group</h5>
        <ul className="list-group">
          {participatingClients.map((client) => (
            <li key={client.id} className="list-group-item d-flex justify-content-between align-items-center">
              <Link to={`/dashboard/clients/${client.id}`}>
                {client.firstName} {client.lastName}
              </Link>
              <button onClick={() => removeClientFromGroup(client.id)} className="btn btn-danger btn-sm">
                Remove
              </button>
            </li>
          ))}
        </ul>

        <h5 className="mt-5">Add More Clients</h5>
        <ul className="list-group">
          {filteredClients.map((client) => (
            <li key={client.id} className="list-group-item d-flex justify-content-between align-items-center">
              <Link to={`/dashboard/clients/${client.id}`}>
                {client.firstName} {client.lastName}
              </Link>
              <button onClick={() => addClientToGroup(client.id)} className="btn btn-primary btn-sm">
                Add
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddClients;
