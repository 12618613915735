import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { getFirestore, doc, setDoc,getDoc, collection, query, where, getDocs } from "firebase/firestore";
import Select from 'react-select'; // Import react-select
import logo from '../../assets/logo.png'; 
import { useNavigate } from 'react-router-dom';

const db = getFirestore();

function HomePage() {
  const [email, setEmail] = useState("");
  const [coaches, setCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState(null); // State to store selected coach
  const { user } = useAuth();
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [showCoachModal, setShowCoachModal] = useState(false);
  const [userRedirect, setUserRedirect] = useState(null);

  useEffect(() => {
    // Fetch list of coaches when component mounts
    const fetchCoaches = async () => {
      const q = query(collection(db, "users"), where("role", "==", "coach"));
      const querySnapshot = await getDocs(q);

      const coachList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        // Check if username exists, publicProfile is true, and at least one of firstName or lastName is present
        if (data.username && data.publicProfile === true && (data.firstName || data.lastName)) {
          let label = data.username;
          
          if (data.firstName || data.lastName) {
            label = `${data.username} (${data.firstName || ''} ${data.lastName || ''})`.trim();
          }
          
          coachList.push({ 
            value: doc.id, 
            label: label.trim() // Ensure there is no trailing space
          });
        }
      });
      setCoaches(coachList);
    };

    fetchCoaches();
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = doc(db, "waitlist", email);
      await setDoc(docRef, { email: email });
      console.log("Document written with ID: ", docRef.id);
      
      alert('Success! Your email has been added to the waitlist.');
      setEmail(""); // Reset the email field
      return <Navigate to="/" replace />; // Navigate back to homepage
      
      // TODO: send confirmation email here
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const navigate = useNavigate();

  const handleCoachSelect = (selectedCoach) => {
    if (selectedCoach && selectedCoach.value) {
      const coachUsername = selectedCoach.label.split(' ')[0]; // Assuming the first part of the label is the username
      navigate(`/website/${coachUsername}/login`); // Navigate to the coach login page
    }
  };

  useEffect(() => {
    const checkUserRole = async () => {
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.role === 'coach') {
              setUserRedirect(<Navigate to="/dashboard" replace />);
            } else if (userData.role === 'client') {
              // Fetch coach data
              const coachRef = doc(db, 'users', userData.coachUid);
              const coachSnap = await getDoc(coachRef);
              
              if (coachSnap.exists()) {
                const coachData = coachSnap.data();
                setUserRedirect(<Navigate to={`/website/${coachData.username}/dashboard`} replace />);
              } else {
                console.error("Coach document doesn't exist");
                // Handle the case where coach data is not found
              }
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    checkUserRole();
  }, [user]);

  if (userRedirect) {
    return userRedirect;
  }

  return (
    <div>
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-surface-dark px-0 py-3">
          <div className="container-xl max-w-screen-xl">
            {/* Logo */}
            <a className="navbar-brand" href="/">
              <img
                src={logo}
                className="h-9"
                alt="Logo"
              />
              
            </a>
          </div>
        </nav>
        <div className="pt-10 pt-md-16 bg-surface-dark overflow-hidden">
          <div className="container-lg max-w-screen-xl">
            <div className="row g-6 align-items-center">
              <div className="col-lg-8 col-xl-7 mx-auto text-lg-center">
                {/* Title */}
                <h1 className="ls-tight font-bolder display-4 text-white mb-7">
                  Design Custom Courses for your Community
                </h1>
                {/* Text */}
                <p className="lead text-white text-opacity-75 mb-12">
                Design and launch personalized apps, create engaging group courses, and chat seamlessly with your clients—all in one platform. 
                  <br></br>
                  <br></br>
                  A new way to engage & monetize your long tail of users. 
                </p>

                {/* Join Waitlist Button */}
                <button className="btn btn-primary me-3" onClick={() => setShowWaitlistModal(true)}>
                  Join the Waitlist
                </button>
                
                {/* Find Coach Button */}
                <button className="btn btn-secondary" onClick={() => setShowCoachModal(true)}>
                  Find Your Coach
                </button>
              </div>
            </div>
            <div className="row mt-lg-n7 transform translate-y-1/4">
              <div className="col-lg-11 col-xl-9 mx-auto">
                <div className="row g-5">
                  <div className="col">
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2"
                      src="https://images.unsplash.com/photo-1612957753858-3ed536c929d1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=800&q=80"
                    />
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2"
                      src="https://images.unsplash.com/photo-1470468969717-61d5d54fd036?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=800&q=80"
                    />
                  </div>
                  <div className="col">
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2 transform translate-y-1/3"
                      src="https://images.unsplash.com/photo-1590556409324-aa1d726e5c3c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=800&q=80"
                    />
                  </div>
                  <div className="col">
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2"
                      src="https://images.unsplash.com/photo-1616453239004-3ab56827fb76?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=800&q=80"
                    />
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2"
                      src="https://images.unsplash.com/photo-1548690312-e3b507d8c110?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=800&q=80"
                    />
                  </div>
                  <div className="col d-none d-sm-block">
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2 transform translate-y-1/3"
                      src="https://images.unsplash.com/photo-1584466977773-e625c37cdd50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=800&q=80"
                    />
                  </div>
                  <div className="col d-none d-sm-block">
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2"
                      src="https://images.unsplash.com/photo-1540206276207-3af25c08abc4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=800&q=80"
                    />
                    <img
                      alt=""
                      className="img-fluid rounded-2 my-2"
                      src="https://images.unsplash.com/photo-1646781618699-ad558759e8fd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=600&h=800&q=80"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Waitlist Modal */}
            {showWaitlistModal && (
              <div className="modal show d-block" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Join Waitlist</h5>
                      <button type="button" className="btn-close" onClick={() => setShowWaitlistModal(false)} />
                    </div>
                    <div className="modal-body text-center">
                      
                      {/* <div className="pt-10 pt-md-16 overflow-hidden">
                        <div className="container-lg max-w-screen-xl">
                          <div className="row g-6 align-items-center">
                            <div className="col-lg-8 col-xl-7 mx-auto text-lg-center">
                              <form className="form-dark w-xl-2/3 mx-auto" onSubmit={handleFormSubmit}>
                                <div className="rounded px-2 py-2 border shadow">
                                  <div className="row">
                                    <div className="col"> 
                                      <input
                                        type="email"
                                        name="email"
                                        className="form-control pl-3 shadow-none bg-transparent border-0"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={handleEmailChange}
                                      />
                                    </div>
                                    <div className="col-auto">
                                      <button type="submit" className="btn btn-block btn-primary">
                                        Join the Waitlist
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <form onSubmit={handleFormSubmit}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        <button type="submit" className="btn btn-primary mt-3">
                          Submit
                        </button>
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Coach Search Modal */}
            {showCoachModal && (
              <div className="modal show d-block" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Find Your Coach</h5>
                      <button type="button" className="btn-close" onClick={() => setShowCoachModal(false)} />
                    </div>
                    <div className="modal-body">
                      <Select
                        options={coaches}
                        onChange={handleCoachSelect}
                        placeholder="Search for a coach..."
                        isClearable
                      />
                      {selectedCoach && (
                        <div className="mt-3">
                          <p>Selected Coach: {selectedCoach.label}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}


          </div>
        </div>
      </div>
    </div>

  );
}

export default HomePage;
