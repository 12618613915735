import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

function PersonalWebsitePage() {
  const { username } = useParams();

  return (
    <Navigate to={`/website/${username}/login`} replace />
  );
}

export default PersonalWebsitePage;
