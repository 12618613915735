// Chat.js
import React, { useState, useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import {
  Chat as StreamChatComponent,
  Channel,
  ChannelHeader,
  MessageList,
  MessageInput,
  ChannelList,
  Thread,
  Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';

const Chat = () => {
    const [client, setClient] = useState(null);
    const [user, setUser] = useState(null);
    const [groupIds, setGroupIds] = useState([]);
    const [clientIds, setClientIds] = useState([]);
    const [loading, setLoading] = useState([]);

    // your Stream app information
    const apiKey = 'yyctydyjthy8'; // Ensure this name matches the route parameter
    const auth = getAuth();
    const coach = auth.currentUser;

    const getInitials = (fullName) => {
        const nameArray = fullName.trim().split(' ');
        if (nameArray.length === 1) {
            return nameArray[0].charAt(0).toUpperCase();
        }
        return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
    };

    const getAvatarUrl = (fullName) => {
        console.log(`full name is ${fullName}`);

        const initials = getInitials(fullName);
        // UI Avatars URL: generates a circle avatar with initials, customize the size and background color
        return `https://ui-avatars.com/api/?name=${initials}&background=random&rounded=true`;
    };

    const fetchUserToken = async (userId) => {
        const response = await fetch(`${window.location.origin}/api/generate-chat-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
        });

        const data = await response.json();
        return data.token;
    };

    const fetchCoachData = async () => {

        if (coach) {
            const coachDocRef = doc(db, "users", coach.uid);
            const coachDocSnap = await getDoc(coachDocRef);

            if (coachDocSnap.exists()) {
                const coachData = coachDocSnap.data();
                
                // Use the username from Firestore or fallback to the coach's first and last name
                const coachName = coachData.username || `${coachData.firstName} ${coachData.lastName}`;
                const coachFullName = `${coachData.firstName} ${coachData.lastName}`;

                setUser({
                    id: coach.uid,
                    name: coachName,
                    image: coachData.image || getAvatarUrl(coachFullName), // Use image from Firestore or generate avatar from initials
                });

            }
        }
    };

    const fetchCoachClients = async () => {
        const q = query(collection(db, "users"), where("coachUid", "==", coach.uid), where("role", "==", "client"));
        const querySnapshot = await getDocs(q);

        const clientsArray = [];
        querySnapshot.forEach((doc) => {
            clientsArray.push({
                id: doc.id, 
                name: `${doc.data().firstName} ${doc.data().lastName}`,
            });
        });

        return clientsArray;
    };

    const fetchCoachGroups = async () => {
        try {
            // Fetch all fitness groups where coachId matches
            const q = query(
                collection(db, "fitness_group"), 
                where("coachId", "==", coach.uid),
                where("deleted_at", "==", null),
                where("archived_at", "==", null),
            );
            const querySnapshot = await getDocs(q);

            const groupsArray = [];

            querySnapshot.forEach((doc) => {
                groupsArray.push({
                    id: doc.id, 
                    name: doc.data().name,
                });
            });


            return groupsArray.length ? groupsArray : [];
        } catch (error) {
            console.error("Error fetching groups:", error);
            return [];
        }
    } ;


    useEffect(() => {
        const initChat = async () => {
            await fetchCoachData();
        };

        initChat();
    }, []);

    useEffect(() => {
        const initializeChatClient = async () => {
            if (user) {
                const chatClient = StreamChat.getInstance(apiKey);
                const token = await fetchUserToken(user.id);

                await chatClient.connectUser(
                    {
                        id: user.id,
                        name: user.name,
                        image: user.image
                    },
                    token
                );

                const fitnessGroups = await fetchCoachGroups();
                const clients = await fetchCoachClients();

                console.log("fitness and client fetches worked");

                const groupIdArray = [];
                const clientIdArray = [];
                

                for (const group of fitnessGroups) {
                    const generalChannel = chatClient.channel('messaging', `${group.id}-general`, {
                        name: `${group.name} General Channel`,
                    });

                    const qaChannel = chatClient.channel('messaging', `${group.id}-QA`, {
                        name: `${group.name} Q&A Channel`,
                    });

                    await generalChannel.create();
                    await qaChannel.create();



                    groupIdArray.push(`${group.id}-general`, `${group.id}-QA`);

                }


                // Create 1-on-1 channels for each client
                for (const client of clients) {
                    clientIdArray.push(`${client.id}-1on1`);
                }

                setGroupIds(groupIdArray);
                setClient(chatClient);
                setClientIds(clientIdArray);
            }
        };

        initializeChatClient();
        setLoading("complete")
    }, [user]);

    useEffect(() => {
        return () => {
            if (client) {
                client.disconnect();
            }
        };
    }, [client]);

    if (!client || (groupIds.length === 0 && clientIds.length === 0)) {
        return <div>Loading...</div>;
    }

    const options = { limit: 10 };
    const filters = {
        type: 'messaging',
        id: { $in: [...groupIds, ...clientIds] },
    };

    return (
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Sidebar />

        <div className="h-screen flex-grow-1 d-flex flex-column overflow-y-lg-auto">
            <Header title="Chat" />

            <div className="container-fluid">
            <StreamChatComponent client={client} theme="messaging light">
                <ChannelList
                        filters={filters}
                        sort={{ last_message_at: -1 }}
                        options={{options}}
                    />
                    <Channel>
                        <Window>
                            <ChannelHeader />
                            <MessageList />
                            <MessageInput />
                        </Window>
                        <Thread />
                    </Channel>
                </StreamChatComponent>
            </div>
        </div>
        </div>
  );
};

export default Chat;
