import React from 'react';

const Header = (props) => {
    return (

        <header className="bg-surface-primary border-bottom py-6">
            <div className="container-fluid">
                <div>
                    <div className="row align-items-center">
                    <div className="col-md-6 col-12 d-flex align-items-center mb-5 mb-md-0">
                        <div className="col-auto"></div>
                        <div className="ms-5">
                        {/* Title */}
                        <h1 className="h2 mb-0">{props.title}</h1>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;