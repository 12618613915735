import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase/firebase';
import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';
import { Link } from 'react-router-dom';
import { createUserDocument } from '../../utils/user';


const Clients = () => {

  const [clients, setClients] = useState([]);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [newClient, setNewClient] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [coachUsername, setCoachUsername] = useState('');

  const auth = getAuth();
  const user = auth.currentUser; // fetches the currently logged in coach
  console.log(user); 

  const fetchClients = useCallback(async () => {
    const q = query(collection(db, "users"), where("role", "==", "client"), where("coachUid", "==", user.uid));
    const querySnapshot = await getDocs(q);

    let clientsArray = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (!data.deleted_at) {
        clientsArray.push({ id: doc.id, ...data });
      }
    });

    console.log(clientsArray);

    setClients(clientsArray);
  }, [user.uid]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleOpenModal = async () => {
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    const coachUsername = docSnap.exists() ? docSnap.data().username : "password"; // Default in case username is missing
    setCoachUsername(coachUsername);

    setShowModal(true);
  };


  const handleAddClient = async () => {
    const { email, firstName, lastName } = newClient;

    if (!coachUsername) {
      console.error('Coach username is not set');
      alert('Unable to create client. Please try again later.');
      return;
    }

    if (!firstName || !lastName || !email) {
      alert('All fields are required. Please fill in all the information.');
      return;
    }

    const defaultPassword = coachUsername;

    try {
      // Call the create-client API endpoint
      const response = await fetch('/api/create-client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password: defaultPassword,
          firstName,
          lastName,
          coachUid: user.uid,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create client');
      }

      const { uid } = await response.json(); // Assuming the API returns the new user's UID

      // Use createUserDocument to add additional data to Firestore
      await createUserDocument({ uid, email }, 'client', user.uid, firstName, lastName);

      // Refresh the clients list after successful addition
      fetchClients();
      setShowModal(false); // Close modal
      setNewClient({ firstName: '', lastName: '', email: '', password: '' }); // Reset form
      alert('Client created successfully!');
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />

      {/* Main content */}
      <div className="h-screen flex-grow-1 d-flex flex-column overflow-y-lg-auto">

        <Header title="Clients" />
        {/* Header */}
  

        {/* Main */}
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">
            <div className="vstack gap-4">
              {/* Filters */}
              <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                <div className="d-flex gap-3">
                  {/* Search */}
                  <div className="input-group input-group-sm input-group-inline">
                    <span className="input-group-text pe-2">
                      <i className="bi bi-search" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </div>
                <button className="btn btn-sm btn-success" onClick={handleOpenModal}>
                    + Add Client
                </button>
                  
                {/* <div className="btn-group">
                  <a
                    href="#"
                    className="btn btn-sm btn-neutral text-primary"
                    aria-current="page"
                  >
                    Active Clients
                  </a>
                  <a href="#" className="btn btn-sm btn-neutral">
                    New Clients
                  </a>
                  <a href="#" className="btn btn-sm btn-neutral">
                    Archived Clients
                  </a>
                  <a href="#" className="btn btn-sm btn-neutral">
                    All Clients
                  </a>
                </div> */}
              </div>
              {/* Table */}
              <div className="card">
                <div className="card-header border-bottom d-flex align-items-center">
                  <h5 className="me-auto">All clients</h5>
                  <div className="dropdown">
                    <a
                      className="text-muted"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bi bi-three-dots-vertical" />
                    </a>
                    <div className="dropdown-menu">
                      <a href="#!" className="dropdown-item">
                        Action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Another action
                      </a>
                      <a href="#!" className="dropdown-item">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-nowrap">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col" className="d-none d-md-table-cell">Next Appointment</th>
                        <th scope="col" className="d-none d-md-table-cell">Last Appointment</th>
                        <th scope="col">Status</th>

                      </tr>
                    </thead>
                    <tbody>

                    {clients.map((client, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link className="text-heading font-semibold" to={`/dashboard/clients/${client.id}`}>
                              {client.firstName} {client.lastName}
                            </Link>
                          </td>
                          <td className="d-none d-md-table-cell">{client.nextAppointment}</td>
                          <td className="d-none d-md-table-cell">{client.lastAppointment}</td>
                          <td>{client.clientStatus}</td>
                          {/* add other fields as needed */}
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
                {/* <div className="card-footer border-0 py-5">
                  <span className="text-muted text-sm">
                    Showing 10 items out of 250 results found
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Modal for Adding Client */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add New Client</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)} />
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">First Name *</label>
                  <input type="text" className="form-control" name="firstName" value={newClient.firstName} onChange={handleInputChange} required />
                </div>
                <div className="mb-3">
                  <label className="form-label">Last Name *</label>
                  <input type="text" className="form-control" name="lastName" value={newClient.lastName} onChange={handleInputChange} required />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email *</label>
                  <input type="email" className="form-control" name="email" value={newClient.email} onChange={handleInputChange} required />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input type="text" className="form-control" name="password" value={`${coachUsername}`} disabled />
                  <small className="text-muted">{`Default password is your handle (${coachUsername})`}</small>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleAddClient}>
                  Add Client
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    
    </div>
  );
};

export default Clients;
