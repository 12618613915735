// ClientNavbar.js
import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import queryString from 'query-string';  // Import the library
import favicon from '../assets/coachcloud_favicon_dark1.png';

const ClientNavbar = ({username}) => {

    const location = useLocation(); // Get location object
    const { tab } = queryString.parse(location.search); // Parse query params

    return (
        
        <nav>
            {/* Web view */}
            <div className="navbar navbar-light bg-light d-lg-flex d-md-flex flex-column flex-nowrap align-items-center h-screen overflow-y-auto px-2 py-md-0 border-end-lg border-end-md w-18 d-none">
                
                {/* Brand */}
                <Link to={`/website/${username}/dashboard`} className="d-none d-md-block py-3 px-2">
                    <img
                    src={favicon}
                    className="w-full h-auto"
                    alt="..."
                    />
                </Link>
                <ul className="nav navbar-nav w-full flex-row flex-md-column flex-none justify-content-between justify-content-md-center gap-3 text-center py-3 py-lg-0 mt-auto">
                    <li className="nav-item">
                        <Link 
                             to={`/website/${username}/scheduling`} 
                             className={`${window.location.pathname === `/website/${username}/scheduling` ? "nav-link p-0 py-md-3 bg-opacity-20 bg-primary rounded-3 text-primary" : "nav-link p-0 py-md-3"}`}
                        >
                            <span className="text-lg">
                                <i className={`fa-calendar ${window.location.pathname === `/website/${username}/scheduling` ? "fa-solid text-primary" : "fa-regular"}`} />
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link  to={`/website/${username}/program`} className={`${window.location.pathname === `/website/${username}/program` ? "nav-link p-0 py-md-3 bg-opacity-20 bg-primary rounded-3 text-primary" : "nav-link p-0 py-md-3"}`}>
                            <span className="text-lg">
                                <i className={`fa-dumbbell ${window.location.pathname === `/website/${username}/program` ? "fa-solid text-primary" : "fa-regular"}`} />
                            </span>
                        </Link>
                    </li>


                    <li className="nav-item">
                        <Link to={`/website/${username}/chat`} className={`${window.location.pathname === `/website/${username}/chat` ? "nav-link p-0 py-md-3 bg-opacity-20 bg-primary rounded-3 text-primary" : "nav-link p-0 py-md-3"}`}>
                            <span className="text-lg">
                                <i className={`fa-message-dots ${window.location.pathname === `/website/${username}/chat` ? "fa-solid text-primary" : "fa-regular"}`} />
                            </span>
                        </Link>
                    </li>
                </ul>
                {/* Push content down */}
                <div className="mt-auto" />
                <ul className="nav navbar-nav flex-row flex-md-column justify-content-between justify-content-md-center text-center py-3 py-lg-0">
                    <li className="nav-item">
                        <Link to={`/website/${username}/account?tab=profile`} className={`${tab=== 'profile' ? "nav-link p-0 py-md-3 rounded-3 text-primary" : "nav-link p-0 py-md-3"}`}>
                            <span className="text-base">
                                <i className={`fa-user ${tab === 'profile' ? "fa-solid text-primary" : "fa-regular"}`} />
                            </span>
                        </Link>
                    </li>
                </ul>
            </div>

            {/* This is the new horizontal navbar for small screens only */}
            <div className="navbar fixed-bottom bg-light d-flex justify-content-around border-top d-md-none" style={{position: "fixed", bottom: 0, width: "100%"}}>
                <Link to={`/website/${username}/dashboard`} className="nav-link">
                    <span className="text-lg">
                        <i className={`fa-house ${window.location.pathname === `/website/${username}/dashboard` ? "fa-solid text-primary" : "fa-regular"}`} />
                    </span>
                </Link>
                <Link to={`/website/${username}/scheduling`} className="nav-link">
                    <span className="text-lg">
                        <i className={`fa-calendar ${window.location.pathname === `/website/${username}/scheduling` ? "fa-solid text-primary" : "fa-regular"}`} />
                    </span>
                </Link>
                <Link to={`/website/${username}/program`} className="nav-link">
                    <span className="text-lg">
                        <i className={`fa-dumbbell ${window.location.pathname === `/website/${username}/program` ? "fa-solid text-primary" : "fa-regular"}`} />
                    </span>
                </Link>
                <Link to={`/website/${username}/chat`} className="nav-link">
                    <span className="text-lg">
                        <i className={`fa-message-dots ${window.location.pathname === `/website/${username}/chat`? "fa-solid text-primary" : "fa-regular"}`} />
                    </span>
                </Link>
                <Link to={`/website/${username}/account?tab=profile`} className="nav-link">
                    <span className="text-lg">
                        <i className={`fa-user ${tab === 'profile' ? "fa-solid text-primary" : "fa-regular"}`} />
                    </span>
                </Link>
            </div>


        </nav>
    )
}

export default ClientNavbar;