import React, { useEffect, useState } from 'react';
import Sidebar from '../../layouts/Coachportal_sidebar';
import Header from '../../layouts/Header';


import { getUserAppointmentsWithClientInfo } from '../../utils/appointments';
import { auth } from '../../firebase/firebase';

// // Import Firestore functions from Firebase SDK
// import { getDoc, doc } from "firebase/firestore";

// // Import your Firestore database instance
// import { db } from '../../firebase/firebase';

//Weather Icon utility function
const getTimeIcon = (date) => {
  const hour = date.getHours();
  if (hour >= 0 && hour < 5) {
    return "bi bi-moon-fill fs-6 me-3";
  } else if (hour >= 5 && hour < 10) {
    return "bi bi-brightness-alt-high-fill fs-4 me-2";
  } else if (hour >= 10 && hour < 17) {
    return "bi bi-brightness-high-fill fs-4 me-2";
  } else if (hour >= 17 && hour < 20) {
    return "bi bi-sunset-fill fs-4 me-2";
  } else {
    return "bi bi-moon-fill fs-6 me-3"; // Default to moon for any other time
  }
};

//Type Icon utility function
const getTypeIcon = (type) => {
  switch (type) {
    case 'private':
      return 'bi bi-person-fill fs-4 me-2';
    case 'group':
      return 'bi bi-people-fill fs-4 me-2';
    default:
      return 'bi bi-person-fill fs-4 me-2'; // Default or fallback icon
  }
};

// Utility function to get location icon based on type
const getLocationIcon = (location) => {
  switch (location) {
    case 'in person':
      return 'bi bi-building-fill fs-5 me-2';
    case 'virtual':
      return 'bi bi-laptop-fill fs-4 me-2';
    default:
      return 'bi bi-question-circle'; // Default/fallback icon
  }
};

const calculatePercentageChange = (current, previous) => {
  if (previous === 0) return current === 0 ? 0 : 100;
  return ((current - previous) / previous) * 100;
};

const Home = () => {
  const [weeksSessions, setWeeksSessions] = useState('Loading details...');
  const [nextSession, setNextSession] = useState('Loading details...');
  const [nextSessionDetails, setNextSessionDetails] = useState('Loading details...');
  const [todaysAppointments, setTodaysAppointments] = useState([]);
  const [percentageChange, setPercentageChange] = useState(null);

  useEffect(() => {
    const fetchSessions = async () => {
      const appointments = await getUserAppointmentsWithClientInfo(auth.currentUser.uid);

      // Set the "Weeks Sessions" count by considering all non-canceled appointments within the current week
      const today = new Date();

      const startOfCurrentWeek = new Date(today);
      startOfCurrentWeek.setDate(today.getDate() - today.getDay() + 1); // Monday
      startOfCurrentWeek.setHours(0, 0, 0, 0);
      startOfCurrentWeek.toISOString();

      const endOfWeek = new Date(startOfCurrentWeek);
      endOfWeek.setDate(startOfCurrentWeek.getDate() + 6);
      endOfWeek.setHours(23, 59, 59, 999);
      endOfWeek.toISOString(); 

      // const apptTime = new Date("2024-05-14T01:00:00.000Z")
      // console.log("startofweek: " + startOfCurrentWeek)
      // console.log("endofweek: " + endOfWeek)
      // console.log("apptTime: " + apptTime);

      // Filter for non-canceled appointments this week
      const currentWeeksAppointments = appointments.filter(
        appointment => 
        new Date(appointment.appointmentDateTime) >= startOfCurrentWeek &&
        new Date(appointment.appointmentDateTime) <= endOfWeek &&
        !appointment.isCancelled
      );

      const currentWeeksSessions = currentWeeksAppointments.length;
      setWeeksSessions(currentWeeksAppointments.length);

      // Previous week's range
      const startOfPreviousWeek = new Date(startOfCurrentWeek);
      startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7);
      startOfPreviousWeek.toISOString();

      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6);
      endOfPreviousWeek.setHours(23, 59, 59, 999);
      endOfPreviousWeek.toISOString();


      // Sessions in the previous week
      const previousWeeksAppointments = appointments.filter(
        appointment => 
          new Date(appointment.appointmentDateTime) >= startOfPreviousWeek &&
          new Date(appointment.appointmentDateTime) <= endOfPreviousWeek &&
          !appointment.isCancelled
      );
      const previousWeeksSessions = previousWeeksAppointments.length;

      // console.log(previousWeeksSessions);

      // Calculate percentage change
      const percentageChange = calculatePercentageChange(currentWeeksSessions, previousWeeksSessions);
      setPercentageChange(percentageChange);

      // Find the next session (future, uncancelled appointments)
      const sortedUpcomingAppointments = appointments
        .filter(appointment => new Date(appointment.appointmentDateTime) > today && !appointment.isCancelled)
        .sort((a, b) => a.appointmentDateTime - b.appointmentDateTime);

      // Set the next session with formatted date and time
      if (sortedUpcomingAppointments.length > 0) {
        const nextAppointment = sortedUpcomingAppointments[0];
        const nextSessionAppointment = sortedUpcomingAppointments[0];
        const date = new Date(nextSessionAppointment.appointmentDateTime);
        const formattedDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
        const formattedTime = new Date(nextSessionAppointment.appointmentDateTime).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        setNextSession(`${formattedDay} @ ${formattedTime}`);


        const clientCount = nextAppointment.clientIds.length;
        const firstClient = nextAppointment.clients[0];
        let detailsText = nextAppointment.portal_notes
          ? `${nextAppointment.portal_notes} with ${firstClient.firstName} ${firstClient.lastName}`
          : `With ${firstClient.firstName} ${firstClient.lastName}`;

        if (clientCount > 1) {
          detailsText += ` + ${clientCount - 1}`;
        }

        setNextSessionDetails(detailsText);
      } else {
        setNextSession("No upcoming sessions");
        setNextSessionDetails('');
      }

      // Filter for today's appointments (PST)
      const startOfTodayPST = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
      startOfTodayPST.setHours(0, 0, 0, 0);
      const endOfTodayPST = new Date(startOfTodayPST);
      endOfTodayPST.setHours(23, 59, 59, 999);

      // Get today's appointments with clients' names
      const todaysAppointments = appointments.filter(
        appointment => new Date(appointment.appointmentDateTime) >= startOfTodayPST &&
          new Date(appointment.appointmentDateTime) <= endOfTodayPST &&
          !appointment.isCancelled
      );

      console.log(todaysAppointments);
      setTodaysAppointments(todaysAppointments);
    };

    fetchSessions();
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <Sidebar />

      {/* Main content */}
      <div className="h-screen flex-grow-1 d-flex flex-column overflow-y-lg-auto">

        <Header title="👋 Welcome!" />
        {/* Header */}
  

        {/* Main */}
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">

            {/* Card stats */}
            <div className="row g-6 mb-6">
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Weeks Sessions</span>
                        <span className="h3 font-bold mb-0">{weeksSessions  !== null ? weeksSessions : "Loading..."}</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                          <i className="bi bi-calendar"></i>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-0 text-sm">
                    <span className={`badge badge-pill bg-opacity-30 ${percentageChange >= 0 ? 'bg-success text-success' : 'bg-danger text-danger'} me-2`}>
                      <i className={`bi bi-arrow-${percentageChange >= 0 ? 'up' : 'down'} me-1`}></i>
                      {Math.abs(percentageChange).toFixed(2)}%
                    </span>
                      <span className="text-nowrap text-xs text-muted">Since last week</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Weeks 💰💰💰 </span>
                        <span className="h3 font-bold mb-0">coming soon</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                          <i className="bi bi-credit-card"></i>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-0 text-sm">
                      <span className="badge badge-pill bg-opacity-30 bg-success text-success me-2">
                        <i className="bi bi-arrow-up me-1"></i>coming soon
                      </span>
                      <span className="text-nowrap text-xs text-muted">Since last month</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">Next Session</span>
                        <span className="h3 font-bold mb-0">{nextSession ? nextSession : "No upcoming sessions"}</span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                          <i className="bi bi-clock-history"></i>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-0 text-sm">
                      <span className="badge badge-pill bg-opacity-30 bg-danger text-danger me-2">
                        {/* <i className="bi bi-arrow-down me-1"></i>-5% */}
                      </span>
                      <span className="text-nowrap text-xs text-muted">
                        {nextSessionDetails ? nextSessionDetails : "No additional details available"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Upcoming Appointments */}
            <div className="card mb-7">
              <div className="card-header">
                <h5 className="mb-0">Todays Sessions</h5>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Time</th>
                      <th scope="col">Location</th>
                      <th scope="col">Type</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {todaysAppointments.length > 0 ? (
                        todaysAppointments.map((appointment, index) => (
                          <tr key={index}>
                            <td>{appointment.name}</td>
                            <td>
                                <i className={`${getTimeIcon(new Date(appointment.appointmentDateTime))}`}></i>
                              {new Date(appointment.appointmentDateTime).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                            </td>
                            <td>
                              <i className={`${getLocationIcon(appointment.location_type)} me-2`}></i>
                              {appointment.location_type.charAt(0).toUpperCase() + appointment.location_type.slice(1)} {/* Capitalize the location type */} 
                            </td>
                            <td>
                              <i className={`${getTypeIcon(appointment.type)} me-2`}></i>
                              {appointment.type.charAt(0).toUpperCase() + appointment.type.slice(1)}
                            </td>
                            <td className="text-end">
                              <a href="#" className="btn btn-sm btn-neutral">
                                View
                              </a>
                              <button
                                type="button"
                                className="btn btn-sm btn-square btn-neutral text-danger-hover"
                              >
                                <i className="bi bi-trash" />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">No appointments scheduled for today.</td>
                        </tr>
                    )}

                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </main>


      </div>
    </div>

    



  );
};

export default Home;
